import * as React from 'react';
import { ModalDemoInterviewProps } from '../Demo';
import { Bot } from 'lucide-react';

const ModalDemoInterview: React.FC<ModalDemoInterviewProps> = ({
  isOpened,
  onClose,
  interview
}) => {

  return (
    <>
      <div
        className={`hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none ${isOpened ? 'open' : 'hidden'
          }`}
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all md:max-w-2xl md:w-full m-3 md:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* Header */}
            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3 className="font-bold text-gray-800 dark:text-white">
                Interview
              </h3>
              <button
                type="button"
                className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>

            {/* Body */}
            <div className="p-4 overflow-y-auto">
              {interview.length === 0 ? (
                <p className="text-gray-800 text-sm dark:text-neutral-400">
                  No interview data found.
                </p>
              ) : (
                <ul className="max-w-[80%] mx-auto">
                  {interview.map((item, idx) => (
                    <React.Fragment key={idx}>
                      {/* Question */}
                      <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11 mb-5">
                        <div className="inline-block size-9 rounded-full">
                          <Bot className="h-10 w-10 text-black dark:text-white" />
                        </div>
                        <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                          <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                            {item.question}
                          </p>
                        </div>
                      </li>

                      {/* Answer */}
                      <li className="flex ms-auto gap-x-2 sm:gap-x-4 mb-5">
                        <div className="grow text-end space-y-3">
                          <div className="inline-block bg-blue-600 rounded-2xl p-4 shadow-sm">
                            <p className="mb-1.5 text-sm text-white">
                              {item.answer}
                            </p>
                          </div>
                        </div>
                        <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                          <span className="text-sm font-medium text-white leading-none">
                            User
                          </span>
                        </span>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              )}
            </div>

            {/* Footer */}
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
              <button
                type="button"
                onClick={onClose}
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={onClose} 
        className={`z-30 hs-overlay-backdrop transition duration fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 dark:bg-neutral-900 ${isOpened ? 'open' : 'hidden'
          }`}
      ></div>
    </>
  );
};

export default ModalDemoInterview;
