import React, { useMemo, useState } from "react";
import { t } from "i18next";
import Footer from "./components/Footer";
import { Tooltip } from "react-tooltip";
import { Check, Info, NotebookText, Search } from "lucide-react";
import Settings from "./Settings";
import ReactMarkdown from "react-markdown";
import ModalDemoIntro from "./components/ModalDemoIntro";
import ModalDemoSynch from "./components/ModalDemoSynch";
import ModalDemoInterview from "./components/ModalDemoInterview";


export interface InterviewItem {
  question: string;
  answer: string;
}

export interface ModalDemoInterviewProps {
  isOpened: boolean;
  onClose: () => void;
  interview: InterviewItem[];
}

const Demo: React.FC = () => {

  const [isModalIntroOpened, setIsModalIntroOpened] = useState<boolean>(true);
  const closeModalIntro = () => setIsModalIntroOpened(false);
  const [isModalSynchOpened, setIsModalSynchOpened] = useState<boolean>(false);
  const closeModalSynch = () => setIsModalSynchOpened(false);
  const [isModalInterviewOpened, setIsModalInterviewOpened] = useState<boolean>(false);
  const closeModalInterview = () => setIsModalInterviewOpened(false);

  const [currentInterview, setCurrentInterview] = useState<InterviewItem[]>([]);

  const textData = {
    healthcare: {
      title: t("demo_healthcare_title"),
      metricOneLabel: "Interview visits",
      metricOneTooltip: "Total views of interview",
      metricOneCount: "432",
      metricOneDiff: "1.7%",
      metricTwoLabel: "Applicants",
      metricTwoCount: "108",
      metricThreeLabel: "Completion Rate",
      metricThreeCount: "24.3%",
      metricThreeDiff: "1.7%",
      tableTitle: "Healthcare Applicants",
      colName: "Name",
      colSummary: "Interview summary",
      colViewInterview: "View interview",
      colVerified: "Verified on LinkedIn",
      colKeywords: "Keywords",
      colRating: "Rating",
      colApplied: "Applied",
      rows: [
        {
          photo:
            "https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Christina Bersh",
          email: "christina@site.com",
          summary:
            "She displayed empathy and a strong sense of responsibility. She excelled in triage scenarios. She fosters positive patient relationships.",
          keyword1: "Patient-oriented",
          keyword2: "Empathetic",
          rating: "80",
          rationale:
            "Consistently strong performance but can improve on advanced documentation.",
          applied: "28 Dec, 12:12",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I've worked in triage and general patient care for five years, which has shaped my empathetic approach and quick decision-making skills."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I worked in a busy ER triage where several patients arrived simultaneously. I had to quickly assess each case, prioritize by severity, and remain calm. This experience taught me the importance of swift assessment and empathetic communication."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I maintain open channels with fellow nurses and doctors, ensuring everyone has up-to-date information. Clear communication helps prevent errors and maintains patient safety."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I update digital records systematically and follow established guidelines. However, I acknowledge I can further enhance my advanced documentation skills, especially in complex cases."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I listen actively, maintain eye contact, and address their concerns promptly. It’s vital for patients to feel heard and respected, especially in stressful medical settings."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I regularly attend hospital-led training sessions and subscribe to a few medical journals to keep current on evolving protocols."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I strictly follow data security measures and only discuss patient information with authorized team members behind closed doors."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "David Harrison",
          email: "david@site.com",
          summary:
            "He excels in clinical evaluations. He values teamwork. He is comfortable with new healthcare tech.",
          keyword1: "Clinical expertise",
          keyword2: "Diagnostic skills",
          rating: "54",
          rationale:
            "Showed potential but missed key details in triage scenario.",
          applied: "20 Dec, 09:27",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I've had about four years of clinical experience, focusing on evaluations and using advanced healthcare technologies."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I handled a case involving a patient with confusing symptoms. By focusing on clinical evaluations and diagnostic tools, I identified the core issue, but I did miss some triage details that I later corrected."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I prioritize regular updates with colleagues. Sharing information promptly helps avoid mistakes and keeps the team in sync."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I use digital records extensively, integrating new tech. However, I’m learning to slow down and cross-check all entries to avoid missing critical details."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I maintain a calm demeanor, speak in a reassuring tone, and ask open-ended questions to understand their concerns fully."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I regularly review clinical guidelines from reputable medical associations and complete online e-learning modules for new protocols."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I maintain secure digital records and only share patient details with colleagues directly involved in each case."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1597113113991-74221fab863e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Andrew Richard",
          email: "andrew@site.com",
          summary:
            "He is proficient in geriatric care. He emphasizes patient comfort. He fosters teamwork among staff.",
          keyword1: "IV Therapy Proficiency",
          keyword2: "Geriatric Care Expertise",
          rating: "66",
          rationale:
            "Demonstrated moderate competencies; improvement needed in advanced patient care.",
          applied: "18 Dec, 15:20",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I've accumulated over four years of direct patient care experience, especially with geriatric populations."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I cared for an older patient with multiple health issues who was uneasy about new treatments. By focusing on comfort, I learned how crucial reassurance is in geriatric settings."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I regularly coordinate with nursing assistants and other staff, ensuring we communicate patient updates clearly and adapt as a unit."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I keep detailed notes, but I’m aiming to strengthen advanced care documentation for more complex procedures and follow-ups."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "Listening without judgment is key. I take time to address their fears and build trust through consistent, respectful interactions."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I attend geriatric-focused healthcare seminars and follow reputable journals to refine my practice for older patients."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I log out of all shared systems promptly and avoid discussing patient details in common areas to safeguard their privacy."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1567921503097-79c293fdadd6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Samia Kartoon",
          email: "samia@site.com",
          summary:
            "She has strong infection control strategies. She excels at crisis management. She is calm under pressure.",
          keyword1: "Infection Control",
          keyword2: "Crisis Management",
          rating: "78",
          rationale:
            "Strong crisis management skills; could focus on deeper empathetic communication.",
          applied: "18 Dec, 15:20",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I've spent the last four years focusing on infection control and crisis management in high-pressure units."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I managed a ward-level infection outbreak by implementing strict isolation protocols. This taught me the value of rapid response and detailed record-keeping."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I keep communication straightforward and calm, which helps the team remain focused during emergencies."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I have a structured checklist for documenting infection control measures and patient progress, ensuring every detail is logged."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I stay calm and explain procedures clearly. I’m also working on engaging in more open dialogue to provide emotional support."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I frequently review CDC updates, attend infection control conferences, and refresh my knowledge on new crisis protocols."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I ensure that any patient records are accessed only by approved personnel and that I never discuss cases in open public areas."
            }
          ]
        },
        {
          photo:
            "https://plus.unsplash.com/premium_photo-1664536392896-cd1743f9c02c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "David Harrison",
          email: "david@site.com",
          summary:
            "He relies on evidence-based practices. He adapts quickly to new tech. He is thorough in documentation.",
          keyword1: "Evidence-Based Nursing",
          keyword2: "Expert In HealthTech",
          rating: "89",
          rationale:
            "Highly proficient in evidence-based practices; a top contender for this role.",
          applied: "15 Dec, 14:41",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Absolutely. I've spent four years delivering patient care backed by evidence-based practices and thorough documentation."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I encountered a complex post-surgery case and used evidence-based guidelines to adjust care plans. This reinforced the importance of research-backed decisions."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I thrive on collaboration, using shared digital platforms for quick updates and consistently briefing team members on patient progress."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I integrate new healthcare tech solutions to maintain accurate records, double-checking entries and aligning them with established protocols."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I use active listening and validate their concerns with clear, evidence-informed explanations, which helps build trust."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I subscribe to leading medical journals and regularly discuss emerging best practices with peers at workshops."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I only use secure digital platforms for data entry and ensure any printed records are stored or disposed of securely."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1545996124-0501ebae84d0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Brian Halligan",
          email: "brian@site.com",
          summary:
            "He shows a strong emphasis on safety. He is calm under pressure. He has strong references.",
          keyword1: "Safety Protocol Adherence",
          keyword2: "Incident Reporting",
          rating: "89",
          rationale:
            "Well-rounded approach to safety and incident reporting; excellent references.",
          applied: "11 Dec, 18:51",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I've been in healthcare for over four years, predominantly focusing on patient safety and risk mitigation."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I managed a patient fall incident, immediately addressed injuries, and initiated a thorough incident report, which underscored the value of quick, structured responses."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I focus on straightforward communication, ensuring every team member knows the safety protocols and their role during emergencies."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I keep a strict protocol checklist and log all relevant data in the EMR, guaranteeing consistent compliance with safety standards."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I approach patients calmly, explain every step of care, and reassure them that their safety is my top priority."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I regularly review Joint Commission standards and attend monthly safety briefings to remain informed about protocol changes."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I use secure EMR systems, limit discussions about patient conditions to private areas, and restrict access to authorized personnel."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Andy Clerk",
          email: "andy@site.com",
          summary:
            "He is skilled at multi-disciplinary collaboration. He demonstrates compassion. He aims for efficient patient turnover.",
          keyword1: "Communicative",
          keyword2: "Pediatric Specialist",
          rating: "45",
          rationale:
            "Basic competencies but needs more training in complex pediatric cases.",
          applied: "28 Dec, 12:12",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "I have just about four years under my belt, primarily working with multidisciplinary teams and pediatric patients."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I had a pediatric case involving complex symptoms. I consulted specialists and learned the importance of thorough, interdisciplinary collaboration."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "Regular check-ins with different departments ensure continuity of care, though I’m still learning finer points for complex cases."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I fill out patient charts meticulously after each visit. However, I recognize the need for more specialized pediatric training in documentation."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I listen patiently, explain treatments in simple terms, and try to comfort children by engaging them at their level."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I attend pediatric-focused workshops and interdepartmental meetings to keep pace with new techniques and standards."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I encrypt digital records, share updates only in secure settings, and always shield pediatric patient details from unauthorized parties."
            }
          ]
        },
        {
          photo:
            "https://plus.unsplash.com/premium_photo-1671656333511-1b43049ea1a0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Barbara Simpson",
          email: "barbara@site.com",
          summary:
            "She uses humor to ease patient anxiety. She is detail-oriented. She is open to additional training.",
          keyword1: "Communication Specialist",
          keyword2: "Stress Management",
          rating: "67",
          rationale:
            "Good sense of humor helps reduce patient anxiety; could further refine detail orientation.",
          applied: "20 Dec, 09:27",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I’ve reached the four-year mark, having worked in environments where humor can ease stress for patients."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I helped a highly anxious patient relax by using light humor and clear, step-by-step explanations."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I keep colleagues updated on patient status and ask for help when needed, emphasizing supportive dialogue."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I meticulously note changes in patient vitals and feedback. I’m also taking extra training to enhance my documentation structure."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I use positive body language and humor to lighten the mood, helping patients feel more comfortable."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I read clinical bulletins and enroll in advanced documentation courses to keep my practice thorough and current."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I keep patient charts password-protected and never disclose personal details unless it’s to the authorized treatment team."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1618835962148-cf177563c6c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Camila Welters",
          email: "cwelt@site.com",
          summary:
            "She is flexible with schedules. She coordinates well with families. She values thorough record-keeping.",
          keyword1: "Psychiatric Support Skills",
          keyword2: "Medication Expert",
          rating: "76",
          rationale:
            "Solid flexibility with scheduling; recommended to strengthen record-keeping approach.",
          applied: "18 Dec, 15:20",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I’ve been handling patient schedules and medication management for more than four years in different clinical setups."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I worked with a psychiatric patient who needed unique scheduling. I learned to adapt care plans around their emotional needs."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I collaborate with doctors, nurses, and family members, ensuring medication plans and schedules align with the patient’s needs."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I document every medication dosage and patient reaction meticulously, but I’m working on making my notes more detailed overall."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I address emotional concerns promptly, maintain open communication, and adapt my schedule to their comfort when possible."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I participate in monthly trainings on psychiatric care and stay tuned to medication updates from reputable medical sources."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I ensure medication logs and patient notes are securely stored and share health information only with authorized team members."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1618835962148-cf177563c6c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Oliver Schevich",
          email: "oliver@site.com",
          summary:
            "He incorporates best practices from prior roles. He invests in continuing education. He can handle multiple patients at once.",
          keyword1: "Acute Patient Monitoring",
          keyword2: "Interdisciplinary",
          rating: "83",
          rationale:
            "Strong multi-tasking abilities, invests in continuous education.",
          applied: "20 Dec, 09:27",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I’ve been in healthcare for a little over four years, often juggling multiple patient caseloads simultaneously."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I managed acute patients simultaneously, utilizing best practices from previous roles. I learned that staying organized and calmly delegating tasks is crucial."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I make sure to coordinate with multiple departments, quickly relaying updates and splitting responsibilities based on expertise."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I rely on structured documentation systems, consistently updating them to reflect patient changes. Continuous education keeps me current on new protocols."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I always explain each procedure step and check in frequently to ensure they’re comfortable, aiming to build trust."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I regularly attend continuing education seminars and reference best-practice guidelines from recognized medical associations."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I restrict discussions of patient details to private forums and ensure that my electronic documentation is password-protected."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1545167622-3a6ac756afa4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Ian Ivy",
          email: "ian@site.com",
          summary:
            "He has advanced training in specialized areas. He is comfortable mentoring others. He fosters a patient-first approach.",
          keyword1: "Advanced Wound Care",
          keyword2: "Team Leadership",
          rating: "95",
          rationale:
            "Exceptional candidate with advanced specialized training and leadership potential.",
          applied: "18 Dec, 15:20",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I've been in the field for over four years, focusing on advanced treatments and mentoring less-experienced team members."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I treated a severe wound requiring specialized techniques. It reinforced the significance of advanced training and patient-first focus."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I mentor junior staff, leading by example while ensuring clear communication so that everyone knows their responsibilities."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I follow rigorous checklists for wound care and record every detail, believing accurate documentation is a cornerstone of best practice."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I combine clear explanations with genuine concern for their comfort, constantly checking in and adjusting care to their needs."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I routinely attend specialized wound care seminars and stay active in professional networks to learn about emerging methods."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I store all documentation in secure, access-controlled systems and only discuss patient details within the direct care team."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1595956553066-fe24a8c33395?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Jason Williams",
          email: "jwilliams@site.com",
          summary:
            "He is enthusiastic about new protocols. He is approachable to patients and staff. He continuously refines his methods.",
          keyword1: "Staff Coordination",
          keyword2: "EMR Documentation",
          rating: "65",
          rationale:
            "Displays enthusiasm but would benefit from more systematic approach to new protocols.",
          applied: "18 Dec, 15:20",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in healthcare?",
              answer: "Yes, I've just reached my fourth year, and I've spent it embracing new protocols and refining patient care methods."
            },
            {
              question: "Can you describe a challenging patient care situation you've handled and what you learned from it?",
              answer: "I once coordinated care for an influx of patients during a busy shift. I learned to systematically delegate tasks to keep protocols consistent."
            },
            {
              question: "How do you approach teamwork and communication in a fast-paced healthcare environment?",
              answer: "I’m always approachable, ensuring staff and patients feel comfortable sharing feedback. Regular briefings keep us aligned."
            },
            {
              question: "How do you ensure thorough patient documentation and adherence to protocols?",
              answer: "I consistently update EMRs and seek out best practices for documenting everything from medication changes to follow-up appointments."
            },
            {
              question: "In what ways do you show empathy and build rapport with patients?",
              answer: "I check in frequently, address concerns with understanding, and adapt my approach based on patient feedback and comfort level."
            },
            {
              question: "Could you share how you stay updated with the latest healthcare protocols or guidelines?",
              answer: "I keep track of evolving guidelines through departmental newsletters and attend relevant training sessions regularly."
            },
            {
              question: "In what ways do you handle patient confidentiality and HIPAA regulations in your daily practice?",
              answer: "I only access patient records for those under my direct care, log out of EMRs when finished, and avoid any public discussions of cases."
            }
          ]
        }
      ]
    },


    retail: {
      title: t("demo_retail_title"),
      metricOneLabel: "Interview visits",
      metricOneTooltip: "Total views of interview",
      metricOneCount: "712",
      metricOneDiff: "2.4%",
      metricTwoLabel: "Applicants",
      metricTwoCount: "220",
      metricThreeLabel: "Completion Rate",
      metricThreeCount: "14.7%",
      metricThreeDiff: "0.9%",
      tableTitle: "Retail Applicants",
      colName: "Name",
      colSummary: "Interview summary",
      colKeywords: "Keywords",
      colVerified: "Verified on LinkedIn",
      colRating: "Rating",
      colApplied: "Applied",
      rows: [
        {
          photo:
            "https://plus.unsplash.com/premium_photo-1683121771856-3c3964975777?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Julia Lambert",
          email: "julia@site.com",
          summary:
            "Extensive experience in cashier duties. Skilled in returns and exchanges. Adept in basic inventory control.",
          keyword1: "POS Handling",
          keyword2: "Customer Engagement",
          rating: "80",
          rationale:
            "Consistent cashier skills, strong customer engagement.",
          applied: "10 Jan, 09:15",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I’ve been a cashier for over four years, perfecting my POS handling and returns processes."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I keep up with our policy updates through weekly manager bulletins and I follow a few retail blogs to monitor changes in shopper behavior."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I like to test out new products when possible and organize quick notes on features, so I can answer customer questions accurately."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "I greeted a confused shopper warmly, offered product suggestions, and ensured a smooth checkout experience that left them very satisfied."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I stay calm, follow store policy, and try to offer alternatives or refunds as needed, ensuring the customer feels heard."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I keep items clearly labeled and grouped, track inventory levels, and place promotional items in high-visibility areas."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I keep an eye on daily metrics and suggest upsells at checkout. Consistent engagement helps boost overall sales."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Alex Brown",
          email: "alex@site.com",
          summary:
            "Has a knack for product merchandising. Creates a welcoming environment. Manages store promotions effectively.",
          keyword1: "Visual Merchandising",
          keyword2: "Promotional Coordination",
          rating: "65",
          rationale:
            "Creative merchandising but can improve promotional planning.",
          applied: "11 Jan, 14:22",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I've worked in various merchandising roles for close to five years, focusing on display creativity."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I check company policy updates on our internal portal and follow social media influencers who discuss emerging retail display ideas."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I research each brand’s style and goals, then experiment with product placement on the floor to see what resonates most with customers."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "I guided a customer to the most suitable product display, providing detailed info on how to combine various items for a better value."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I stay professional, politely walk them through our return process, and try to resolve issues on the spot if possible."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I love creating eye-catching displays but am refining my skills in timing promotions to optimize store traffic."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I actively track promotions, gather customer feedback, and look for ways to adjust our displays if sales lag behind."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1570158268183-d296b2892211?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Carlos Rodriguez",
          email: "carlos@site.com",
          summary:
            "He has a background in stock management. He maintains clean aisles. He handles high-volume traffic during sales.",
          keyword1: "Inventory Control",
          keyword2: "High-Volume Sales",
          rating: "71",
          rationale:
            "Handles stock well under pressure, minor improvements in aisle upkeep.",
          applied: "12 Jan, 16:03",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I’ve spent around four years managing stock in high-traffic store environments."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I attend team briefings before big sales, watch competitor strategies, and review any updated stock management protocols provided by HQ."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I categorize items based on popularity and brand guidelines, then review online resources or product manuals for deeper insights."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "During a big sale rush, I proactively approached customers to help them find items quickly, speeding up their shopping."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I remain calm, confirm their purchase details, and offer solutions like exchanges or store credit. Maintaining respect is key."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I prioritize clear signage, ensuring items are in their correct locations, and restock regularly to keep aisles organized."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I keep track of inventory and adjust displays based on high-demand items during sales. Smooth restocking helps maintain high turnover."
            }
          ]
        },
        {
          photo:
            "https://plus.unsplash.com/premium_photo-1664541336896-b3d5f7dec9a3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Maria Lee",
          email: "mlee@site.com",
          summary:
            "Focused on boosting customer loyalty. Actively upsells. Reliable with end-of-day financial reconciliation.",
          keyword1: "Upselling Techniques",
          keyword2: "Cash Reconciliation",
          rating: "77",
          rationale:
            "Reliable reconciliation skills, good upselling approach.",
          applied: "13 Jan, 09:50",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I’ve been in retail for five years, focusing on upselling and loyalty program strategies."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I attend staff meetings where we discuss policy changes, and I also track customer loyalty trends through industry newsletters."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I study the best-seller lists, look into each brand’s unique selling points, and experiment with bundle deals for customers."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "I introduced them to our loyalty program and bundled products for a better deal, helping them save money while boosting sales."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "By calmly explaining our policy and offering store credit or refunds when valid. Clear communication can defuse tension."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I keep best-selling items front and center. I also update display signs to highlight deals and special offers."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I track end-of-day totals carefully and strategize upselling. By balancing promotions and add-on suggestions, we meet goals consistently."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Brandon White",
          email: "bwhite@site.com",
          summary:
            "Passionate about brand representation. Good team player. Skilled in conflict resolution with customers.",
          keyword1: "Brand Knowledge",
          keyword2: "Conflict Resolution",
          rating: "80",
          rationale:
            "Stands out in brand promotion, effectively handles conflicts.",
          applied: "14 Jan, 15:40",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I’ve spent over four years immersed in brand representation and customer relations."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I frequently check updates from brand partners and read retailer case studies to see how others handle brand representation."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I chat with brand reps, learn key features of new lines, and pass that knowledge on to teammates for consistent messaging."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "I used in-depth brand knowledge to suggest complementary products, showing them the full potential of our lineup."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I keep a cool head, hear them out fully, then work within our policies to find a fair resolution."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I make sure the displays are clean and consistent with brand standards. Visual cohesion is crucial for brand perception."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I keep track of daily goals and collaborate with my team, sharing tips on cross-selling and effective product pitching."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Liam Johnson",
          email: "liam@site.com",
          summary:
            "Specializes in seasonal displays. Monitors weekly sales targets. Excels in cross-selling strategies.",
          keyword1: "Seasonal Displays",
          keyword2: "Cross-selling",
          rating: "60",
          rationale:
            "Basic cross-selling skills, can refine seasonal strategies.",
          applied: "15 Jan, 11:25",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, for the last four years I've primarily focused on seasonal campaigns and product rotations."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I participate in store-wide training sessions each season and browse competitors' promotional layouts for ideas."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I focus on cross-selling pairs—like holiday-themed items or brand tie-ins—so I need to know the specifics of each brand’s seasonal lineup."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "I introduced them to complementary products tied to our seasonal theme, which increased their basket size."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I follow the policy and look for win-win solutions, staying polite and making sure they leave with a positive impression."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I enjoy setting up seasonal sections, though I'm working on timing promotions to align better with weekly sales goals."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I push cross-selling opportunities and keep an eye on weekly numbers, adjusting displays if certain items underperform."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Mark Evans",
          email: "mark@site.com",
          summary:
            "Thrives in fast-paced settings. Experienced with store openings. Great with short-staff scheduling.",
          keyword1: "Store Opening Procedures",
          keyword2: "Scheduling",
          rating: "55",
          rationale:
            "Thrives in fast-paced environment, scheduling tasks need more structure.",
          applied: "16 Jan, 17:05",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I’ve been managing new store openings and busy periods for about four years now."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I keep in close contact with our district manager, read monthly ops updates, and follow key retail news platforms."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "Before each store opening, I review the brand sheets thoroughly, so I can guide staff on item locations and highlights."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "On a hectic morning, I personally welcomed customers at the door, offering a quick rundown of new store layouts."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I remain composed, clarify store policies, and try to solve their issues swiftly, especially during busy periods."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I prioritize essential items near the entrance for store openings and ensure promotional signage is visible right away."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I coordinate staff schedules to match peak times, though I’m honing my skills to create more structured scheduling plans."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1614204424926-196a80bf0be8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Nancy Drew",
          email: "nancy@site.com",
          summary:
            "Strong personal shopper experience. Tailors recommendations to client needs. Skilled in phone-based sales.",
          keyword1: "Personal Shopper",
          keyword2: "Phone Sales",
          rating: "69",
          rationale:
            "Strong personal shopper background, average phone-based sales.",
          applied: "17 Jan, 08:42",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I’ve been a personal shopper and phone sales specialist for around four years."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I frequently speak with store managers, get updates on new arrivals, and browse online forums to see how other personal shoppers succeed."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I keep a personalized checklist for each client category, ensuring I can recommend the right items or brands during phone consultations."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "I provided a personalized shopping list based on a quick chat about their preferences, boosting their overall satisfaction."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I calmly walk them through their options, either a direct refund or an exchange, ensuring they feel validated."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I rely on my personal shopper insights to feature items that customers often buy together, optimizing store flow."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I follow up on phone leads and tailor product suggestions to drive sales, though I'm working on refining my phone pitch techniques."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1514626585111-9aa86183ac98?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Patricia Gray",
          email: "patricia@site.com",
          summary:
            "Expert in brand cross-promotion. Creates curated window displays. Organized in backroom stock management.",
          keyword1: "Brand Cross-Promotion",
          keyword2: "Window Displays",
          rating: "73",
          rationale:
            "Effective window displays, can sharpen brand cross-promotion.",
          applied: "18 Jan, 10:14",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I’ve done window displays and cross-promotions for multiple brands over the past four years."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I attend brand training webinars, keep a close eye on internal policy updates, and follow top visual merchandisers on social media."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I study each brand’s target audience and style guides, then arrange window displays that highlight complementary items together."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "I invited them to explore complementary brands displayed in our window, turning a quick visit into a higher-value purchase."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I remain polite, identify what went wrong, and offer a solution—like exchanging items or suggesting a different brand match."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I craft curated window displays that showcase different brands working together, and I rotate them regularly to maintain freshness."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I cross-promote in-store events and keep the backroom organized so we never run out of popular items on the floor."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1590086782792-42dd2350140d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Oliver Holt",
          email: "oliver@site.com",
          summary:
            "Friendly and helpful approach. Resolves complaints diplomatically. Maintains strong loyalty card sign-ups.",
          keyword1: "Diplomatic Complaint Handling",
          keyword2: "Loyalty Programs",
          rating: "84",
          rationale:
            "Excellent complaint resolution, fosters loyalty program growth.",
          applied: "19 Jan, 13:29",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I’ve been working the floor and handling customer relations for about four and a half years."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I read our policy updates thoroughly and also keep up with newsletters about loyalty programs and best practices in customer relations."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I pay close attention to brand training sessions, ask for product demos where possible, and encourage customers to give feedback on new items."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "I greeted them personally and offered immediate assistance, which led to them signing up for our loyalty card on the spot."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I listen attentively, stay diplomatic, and ensure they leave feeling their concerns were taken seriously."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I look at customer foot traffic patterns and place promotions where they’re most likely to see them, driving more sign-ups."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I highlight our loyalty program benefits, which encourages repeat visits and boosts overall sales performance."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Simon King",
          email: "simonk@site.com",
          summary:
            "He coordinates floor resets. He partners well with leadership. He is skilled in daily metrics tracking.",
          keyword1: "Floor Resets",
          keyword2: "Daily Metrics",
          rating: "75",
          rationale:
            "Cooperative with leadership, consistent in daily metrics tracking.",
          applied: "20 Jan, 14:10",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I've been coordinating store floor plans for over four years, including resets and seasonal changes."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I keep a close eye on internal policy memos and attend cross-store meetings to learn about updates and emerging best practices."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I map out each brand’s key SKUs, track their daily performance, and adjust floor placement for maximum visibility."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "When a customer was overwhelmed by options, I guided them to our newly reset floor section, simplifying their decision process."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I check their purchase records, listen to their concerns, and communicate with leadership for swift resolutions."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I handle periodic floor resets, ensuring each section aligns with current promotions and is tracked in our daily metrics."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I review daily sales data, identify which zones need improvement, and adjust product placement or signage accordingly."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1611695434398-4f4b330623e6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Daniel Prince",
          email: "daniel@site.com",
          summary:
            "He is attentive to detail in product placement. He has a flexible schedule. He is often recognized for upselling high-value items.",
          keyword1: "Product Placement",
          keyword2: "High-Value Upselling",
          rating: "68",
          rationale:
            "Attentive product placement, recognized upselling potential.",
          applied: "21 Jan, 10:10",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in retail?",
              answer: "Yes, I've been arranging products and upselling high-value items for around five years."
            },
            {
              question: "How do you stay informed about store policies and new retail trends?",
              answer: "I routinely check for store bulletins, attend any optional training on advanced upselling, and monitor what high-end competitors do."
            },
            {
              question: "What strategies do you use to familiarize yourself with different product lines or brands?",
              answer: "I review each product’s unique selling points, compare them to similar items, and prep quick talking points for when customers inquire."
            },
            {
              question: "Tell me about a time you successfully engaged a customer to enhance their shopping experience.",
              answer: "A customer looked at a premium item, and I explained its benefits in detail, leading to an upsell and a happy purchase."
            },
            {
              question: "How do you handle challenging customers or returns?",
              answer: "I keep a polite demeanor, verify receipts, and offer an appropriate exchange or store credit if the return is valid."
            },
            {
              question: "How do you approach merchandise organization and promotional setups?",
              answer: "I place high-value items at eye level and ensure clear, appealing signage to draw attention."
            },
            {
              question: "What do you do to ensure sales targets and store goals are met?",
              answer: "I’m flexible with my shifts, stepping in to handle busy periods, and I share my upselling tactics with colleagues."
            }
          ]
        },
      ],
    },
    

    foodService: {
      title: t("demo_food_service_title"),
      metricOneLabel: "Interview visits",
      metricOneTooltip: "Total views of interview",
      metricOneCount: "4020",
      metricOneDiff: "1.1%",
      metricTwoLabel: "Applicants",
      metricTwoCount: "259",
      metricThreeLabel: "Completion Rate",
      metricThreeCount: "19.2%",
      metricThreeDiff: "0.4%",
      tableTitle: "Food Service Applicants",
      colName: "Name",
      colSummary: "Interview summary",
      colViewInterview: "View interview",
      colKeywords: "Keywords",
      colVerified: "Verified on LinkedIn",
      colRating: "Rating",
      colApplied: "Applied",
      rows: [
        {
          photo:
            "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Tammy Lee",
          email: "tammy@site.com",
          summary:
            "Experienced line cook. Handles high-volume orders well. Adheres strictly to sanitation rules.",
          keyword1: "Line Cooking",
          keyword2: "Sanitation Practices",
          rating: "72",
          rationale:
            "Good line cook under pressure, adheres to hygiene standards.",
          applied: "05 Feb, 08:10",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I’ve been cooking on the line for about five years, mostly in high-volume kitchens that prioritize sanitation."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I attend local culinary workshops and routinely check health department notices to ensure all protocols are current."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I keep separate prep tools and clearly label ingredients, so I can quickly adapt dishes without risking cross-contamination."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I prepare ingredients in advance, coordinate closely with the kitchen team, and stick to a systematic approach for each order."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I once noticed a cross-contamination risk and immediately sanitized the station, discarding any compromised ingredients."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I value direct feedback from chefs and coworkers, making adjustments to seasonings, cooking times, or plating as needed."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I communicate clearly, keep my station organized, and step in to help others if I see them overwhelmed."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Sam Smith",
          email: "sam@site.com",
          summary:
            "Proficient in pastry work. Collaborative in kitchen teams. Maintains inventory of baking supplies.",
          keyword1: "Pastry Experience",
          keyword2: "Inventory Management",
          rating: "78",
          rationale:
            "Skilled in pastries, keeps accurate inventory, slight improvement needed in new recipe adaptation.",
          applied: "05 Feb, 12:45",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I’ve been working in pastry roles for around four years, focusing on baked goods and desserts."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I subscribe to pastry magazines, watch online tutorials, and keep a close eye on seasonal dessert trends."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I adapt recipes by substituting alternative flours or adjusting sugar levels, and I label everything clearly to avoid mix-ups."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I batch-process pastry dough and keep essential items pre-measured, ensuring a smooth flow during busy shifts."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "When a batch of supplies was nearing its expiration date, I immediately removed and replaced them to uphold safety standards."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I test new recipes in small batches, seeking immediate feedback from my team, then refine before full-scale production."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I coordinate closely with the front-of-house to manage pastry orders and ensure the team remains informed about baking timelines."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Michael Brown",
          email: "michael@site.com",
          summary:
            "He focuses on front-of-house. He is great at upselling daily specials. He is skilled in conflict de-escalation with customers.",
          keyword1: "Upselling",
          keyword2: "Conflict De-escalation",
          rating: "65",
          rationale:
            "Capable front-of-house, strong upselling but can refine conflict resolution.",
          applied: "06 Feb, 09:20",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, about five years now, primarily in front-of-house and customer interaction roles."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I read hospitality blogs, keep up with local restaurant news, and attend occasional workshops on customer service."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I coordinate with the kitchen to confirm feasible changes and clearly communicate these options when upselling dishes."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "Though I’m mostly front-of-house, I stay in sync with the kitchen to pace orders and avoid overwhelming staff."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I once spotted a spilled drink near the serving area and quickly cleaned and sanitized the space to prevent accidents."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "While I don’t cook often, I pass customer feedback to the kitchen team, adjust my upselling approach, and refine my table service."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I keep a clear line of communication with the kitchen, announcing table numbers for daily specials promptly and ensuring smooth coordination."
            }
          ]
        },
        {
          photo:
            "https://plus.unsplash.com/premium_photo-1664541336896-b3d5f7dec9a3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Emily Clark",
          email: "emily@site.com",
          summary:
            "Strong background in food prepping. Ensures food safety compliance. Manages dietary restrictions effectively.",
          keyword1: "Food Preparation",
          keyword2: "Allergen Management",
          rating: "85",
          rationale:
            "Excellent allergen management, ensures compliance and food safety.",
          applied: "06 Feb, 14:10",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I've worked for over four years focusing on safe, efficient food prep."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I follow FDA updates, take refresher courses online, and monitor allergen guidelines to keep meals safe for all customers."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I label every ingredient carefully and often designate a separate prep station for allergy-friendly or custom orders."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I pre-prep ingredients in labeled containers, ensuring quick assembly during rushes without compromising safety."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I once caught a cross-contact risk with allergens, so I switched utensils and sanitized surfaces immediately."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I ask servers for feedback on any dietary requests missed, then tweak my prep methods accordingly."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I clearly label allergen-free zones and communicate any special requests to the entire team, maintaining a consistent workflow."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Carlos Gonzalez",
          email: "carlos@site.com",
          summary:
            "Managed large kitchen teams. Oversees production line. Skilled at quality checks and plating.",
          keyword1: "Kitchen Management",
          keyword2: "Quality Assurance",
          rating: "88",
          rationale:
            "Manages kitchen teams effectively, high quality control standards.",
          applied: "07 Feb, 10:50",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I’ve led teams in large-scale kitchen operations for over four years."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I network with fellow kitchen managers, read industry journals, and periodically attend culinary expos to see new techniques."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I usually assign a specific cook to handle that request, ensure the plating matches the customer’s needs, and double-check final details."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I delegate tasks according to each team member’s strengths and ensure stations are always stocked to handle peak times."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I regularly conduct spot checks; once I found an issue with expired ingredients and immediately discarded them and audited storage systems."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I hold brief post-shift meetings to gather input and adjust plating techniques or station assignments if needed."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I keep a clear chain of command, ensuring everyone knows their station duties, which helps keep quality consistent and morale high."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Lawrence White",
          email: "lawrence@site.com",
          summary:
            "Courteous barista. Proficient in latte art. Maintains cleanliness in open kitchen setups.",
          keyword1: "Barista Skills",
          keyword2: "Latte Art",
          rating: "70",
          rationale:
            "Courteous barista, can improve speed while maintaining latte art quality.",
          applied: "07 Feb, 15:40",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I've worked as a barista in multiple busy cafés for over four years."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I attend coffee expos, follow specialty roaster updates, and keep up with local health code changes for open-kitchen cafes."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I keep a variety of milk substitutes and ensure the frother is thoroughly cleaned before each custom drink to avoid cross-contamination."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "As a barista, I batch-prepare coffee bases where possible, so I can focus on latte art and custom orders efficiently."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I noticed a milk frother not being cleaned properly between uses, so I enforced a strict cleaning schedule and documented it."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "If customers mention slow service, I practice prepping multiple orders simultaneously without sacrificing latte art presentation."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I coordinate with fellow baristas and servers, ensuring drinks are timed well with main courses in an open setup."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Andrew Kim",
          email: "akim@site.com",
          summary:
            "Efficient in meal assembly. Expertise in buffet setups. Coordinates well with back-end staff.",
          keyword1: "Meal Assembly",
          keyword2: "Buffet Setup",
          rating: "60",
          rationale:
            "Basic buffet setup skills, collaboration with back-end staff could be stronger.",
          applied: "08 Feb, 10:25",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I've spent about four years working on large-scale meal assembly, especially for buffet lines."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I regularly watch buffet-focused webinars, read catering forums, and test new serving methods for quicker turnover."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I maintain a separate section for allergen-free or special diet dishes, label them clearly, and train staff on each dish’s contents."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I focus on quick assembly of pre-cooked items for buffets. Timing is everything, especially for larger events."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I once spotted temperature discrepancies at the buffet line, so I adjusted the heaters and rotated dishes promptly."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I seek input from both front-of-house staff and back-end chefs to ensure I'm meeting presentation and quality expectations."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I coordinate with the back-end staff for timely refills. I’m trying to improve communication during peak buffet hours."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Jessica Long",
          email: "jessl@site.com",
          summary:
            "Strong table-waiting experience. Suggests pairings. Skilled in verifying customer IDs for alcohol orders.",
          keyword1: "Table Service",
          keyword2: "Alcohol Service Compliance",
          rating: "79",
          rationale:
            "Strong table-service approach, proficient in alcohol compliance checks.",
          applied: "08 Feb, 16:05",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I’ve been working table service for around four years now, from casual to more formal environments."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I read industry blogs for fine dining updates, attend quick server trainings, and follow changes in alcohol serving regulations."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I double-check with the kitchen for allergy or special requests, then confirm every detail with the customer to avoid mistakes."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I don't cook, but I do coordinate closely with the kitchen to stagger table orders and manage rush hours effectively."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I noticed a drink spill near a table and quickly sanitized the area to prevent slips and maintain hygiene."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I discuss any feedback on food quality with the kitchen and refine my approach to table service, including meal pacing."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I communicate table needs promptly, confirm dietary restrictions, and verify alcohol orders by checking IDs thoroughly."
            }
          ]
        },
        {
          photo:
            "https://plus.unsplash.com/premium_photo-1670588775983-666b23590ffc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Roberta Martin",
          email: "roberta@site.com",
          summary:
            "Handles phone and online orders. Organizes timely deliveries. Great at multi-tasking in rush hours.",
          keyword1: "Order Management",
          keyword2: "Delivery Coordination",
          rating: "74",
          rationale:
            "Multi-tasks well under rush hours, consistent order management.",
          applied: "09 Feb, 09:40",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I’ve managed phone and online ordering systems for around five years now."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I track new delivery apps, read up on best practices for order management, and coordinate closely with third-party couriers."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I confirm every request with the kitchen, label the packaging carefully, and specify any allergen details on delivery notes."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I mainly handle the ordering side, so I ensure the kitchen is updated about large incoming orders ASAP."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I verify that food is packaged correctly and at the right temperature for deliveries, working with the kitchen to fix any issues."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I relay customer feedback on late or incorrect orders to the kitchen so we can adjust our workflow for future deliveries."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I keep an organized list of orders and communicate actively with the kitchen and delivery staff, especially during peak times."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1542327897-d73f4005b533?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Eric Zhang",
          email: "eric@site.com",
          summary:
            "Creates daily menus. Knowledgeable in local sourcing. Keeps track of ingredient freshness.",
          keyword1: "Menu Planning",
          keyword2: "Local Sourcing",
          rating: "82",
          rationale:
            "Innovative in menu creation, good local sourcing approach.",
          applied: "09 Feb, 11:30",
          verified: false,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I’ve been planning menus and sourcing local ingredients for roughly five years."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I follow farm-to-table movements, attend local producer events, and adapt seasonal guidelines from top culinary experts."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I discuss alternatives directly with them, swapping ingredients or adjusting portion sizes to accommodate allergies or preferences."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I plan menus that can be adapted quickly, using local, fresh ingredients that cook efficiently when demand spikes."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I ensure all produce is checked upon delivery, discarding anything that doesn’t meet freshness standards immediately."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I tweak daily specials based on diner feedback, adjusting ingredient choices or portion sizes as needed."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I coordinate with suppliers, chefs, and line cooks to keep fresh ingredients stocked, ensuring consistency in dishes."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1589386417686-0d34b5903d23?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Paul Davis",
          email: "paul@site.com",
          summary:
            "He is a team-oriented sous chef. He is quick to learn new recipes. He focuses on cost control and waste reduction.",
          keyword1: "Sous Chef Skills",
          keyword2: "Cost Control",
          rating: "81",
          rationale:
            "Team-focused sous chef, efficiently reduces waste.",
          applied: "09 Feb, 15:00",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I’ve been working as a sous chef for over four years, balancing line responsibilities and efficiency."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I read culinary trade magazines, watch new technique demos online, and track ingredient price trends to control costs."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "I integrate these requests into our prep schedule, double-check inventory for special items, and minimize waste by planning ahead."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I ensure my prep is efficient, breaking tasks into manageable steps, and delegating properly to keep the line running smoothly."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I noticed potential cross-contact in our prep area, so I halted production, sanitized immediately, and retrained the staff involved."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I gather input from both the head chef and junior cooks, adjusting recipes and processes to balance flavor with cost efficiency."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I lead by example, staying calm, offering help to others when possible, and making sure we minimize waste at every step."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Henry Ross",
          email: "hross@site.com",
          summary:
            "He is a friendly host. He manages reservations. He is skilled in quick problem-solving for seating issues.",
          keyword1: "Reservation Management",
          keyword2: "Customer Greeting",
          rating: "68",
          rationale:
            "Friendly host, quick problem solver, can refine reservation management flow.",
          applied: "10 Feb, 10:15",
          verified: true,
          interview: [
            {
              question: "Do you have at least 4 years of experience in foodservice?",
              answer: "Yes, I’ve been handling front-of-house hosting and reservation management for about five years."
            },
            {
              question: "How do you stay updated on evolving foodservice standards or industry trends?",
              answer: "I read restaurant industry blogs for tips on seating efficiency and also keep track of local events that might affect reservations."
            },
            {
              question: "How do you handle special dietary needs or custom menu requests from customers?",
              answer: "When customers mention them during reservation calls, I make sure the kitchen is informed ahead of time and note it in the system."
            },
            {
              question: "How do you manage cooking under high-volume, time-sensitive conditions?",
              answer: "I’m mainly at the front, so I manage reservations effectively to stagger seating and help keep the kitchen’s load balanced."
            },
            {
              question: "Can you describe a scenario where you had to handle a sanitation or food safety challenge?",
              answer: "I swiftly handled a spill in the waiting area, sanitizing and redirecting guests to ensure safety and smooth traffic flow."
            },
            {
              question: "How do you incorporate feedback to improve your cooking or workflow?",
              answer: "I note any bottlenecks from the diners’ perspective and share them with the kitchen, aiming to refine our seating strategy."
            },
            {
              question: "How do you approach teamwork in a busy kitchen environment?",
              answer: "I stay in constant communication, letting the kitchen know about large parties or special requests in advance to avoid chaos."
            }
          ]
        }
      ]
    },
    

    it: {
      title: t("demo_it_title"),
      metricOneLabel: "Interview visits",
      metricOneTooltip: "Total views of interview",
      metricOneCount: "4830",
      metricOneDiff: "3.2%",
      metricTwoLabel: "Applicants",
      metricTwoCount: "129",
      metricThreeLabel: "Completion Rate",
      metricThreeCount: "32.4%",
      metricThreeDiff: "2.1%",
      tableTitle: "Software Engineering Applicants",
      colName: "Name",
      colSummary: "Interview summary",
      colViewInterview: "View interview",
      colKeywords: "Keywords",
      colVerified: "Verified on LinkedIn",
      colRating: "Rating",
      colApplied: "Applied",
      rows: [
        {
          photo:
            "https://plus.unsplash.com/premium_photo-1671656333460-793292581bc6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Alicia Turing",
          email: "alicia@site.com",
          summary:
            "Strong in algorithmic thinking. Proficient in Python and C++. Demonstrated leadership on open-source projects.",
          keyword1: "Algorithmic Expertise",
          keyword2: "Open-source Leadership",
          rating: "92",
          rationale:
            "High-level algorithmic skills, strong leadership in open-source.",
          applied: "01 Mar, 09:15",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "I mainly use Python and C++, so I don’t have four years of Java experience. My Java exposure has been limited to small projects."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I’m an EU citizen, so I’m free to work across EU member states."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I worked on an open-source project involving pathfinding. I optimized the algorithm’s complexity, reducing runtime significantly."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I start by clearly defining requirements, then outline modules and data flow, ensuring we leverage proven design patterns."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I encourage structured pull requests and continuous feedback. Mentoring new contributors is also a key part of my process."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I coordinated a team for a major open-source release, assigning tasks and ensuring each member understood the broader vision."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I’ve studied Java concurrency concepts, like synchronized blocks and locks, but I rely on proven concurrency libraries when I do use Java."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I’m not deeply tied to a specific framework in Java, but I’ve tried Spring Boot briefly for REST services. I liked how it simplifies setup."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Adam Lovelace",
          email: "adam@site.com",
          summary:
            "Focus on software design patterns. Skilled in system architecture. Keen interest in data modeling.",
          keyword1: "Design Patterns",
          keyword2: "Data Modeling",
          rating: "88",
          rationale:
            "Solid design patterns knowledge, proficient in data modeling.",
          applied: "01 Mar, 10:10",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "Yes, I’ve been working with Java for about six years, often using patterns like Factory and Singleton in enterprise apps."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "I do. My permanent residency status covers any EU-based role."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I built a feature using the Strategy pattern to handle varying data formats without rewriting the entire code base."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I usually start with UML diagrams to clarify interactions, then apply patterns like Observer or Factory where relevant."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I maintain detailed documentation and encourage all reviewers to suggest structural changes, not just syntax fixes."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I led the re-architecture of a legacy codebase, guiding the team to modularize the system for better maintainability."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I favor the Executor framework for thread management and use synchronized collections or locks where needed for thread safety."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I’m a fan of Spring for its IoC container and easy integration with databases, plus I use Hibernate for robust ORM support."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1570158268183-d296b2892211?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Grant Hopper",
          email: "grant@site.com",
          summary:
            "He enjoys debugging complex code bases. He has experience with distributed systems. He is an active contributor to code reviews.",
          keyword1: "Debugging",
          keyword2: "Distributed Systems",
          rating: "85",
          rationale:
            "Strong debugging capability, valuable experience in distributed systems.",
          applied: "02 Mar, 11:20",
          verified: false,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "Yes, I’ve debugged Java microservices extensively, especially focusing on concurrency bugs over roughly five years."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Not currently. I’d need sponsorship to work in the EU."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I debugged a concurrency issue in a microservices environment, which required analyzing logs across multiple services."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I consider scalability and fault tolerance. I break down services and ensure each has clear boundaries for better debugging."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I review merges for concurrency pitfalls, adding comments on potential race conditions or data integrity issues."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I spearheaded a debugging initiative, mentoring junior devs on tracing logs and identifying root causes in distributed apps."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I rely on concurrency utilities like CompletableFuture and analyze thread dumps when debugging performance bottlenecks."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I often use Spring Cloud for distributed setups. The integrated discovery and configuration solutions help identify concurrency issues early."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1573140247632-f8fd74997d5c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Linda Torvalds",
          email: "linda@site.com",
          summary:
            "Expert at kernel development. Highly proficient in Git workflow. Mentors junior engineers.",
          keyword1: "Kernel Development",
          keyword2: "Mentorship",
          rating: "95",
          rationale:
            "Expert kernel developer, outstanding mentorship quality.",
          applied: "02 Mar, 13:45",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "I’ve primarily focused on kernel and C/C++, so my Java experience is under four years."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I do. I’ve worked in several EU-based projects under that permit."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I optimized a kernel scheduler algorithm, reducing context-switch overhead and improving overall performance."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I break down the kernel modules and assess how changes will interact with the hardware, focusing on minimal overhead."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I encourage well-documented commits and guide junior developers on writing maintainable code with clear commit messages."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I led a cross-functional kernel update, ensuring each team understood the changes and coordinated merges seamlessly."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I’m familiar with the JVM model, so I typically leverage synchronized methods and the concurrency package if I must code in Java."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I haven’t dived deeply into Java frameworks, but I’ve seen teams succeed with Spring Boot for microservices due to its clear structure."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1545167622-3a6ac756afa4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Mark Hamilton",
          email: "mark@site.com",
          summary:
            "Specializes in reliability and testing. DevOps enthusiast. Skilled in building robust CI/CD pipelines.",
          keyword1: "Reliability Engineering",
          keyword2: "CI/CD",
          rating: "90",
          rationale:
            "Reliable in testing and CI/CD, ensures system reliability.",
          applied: "03 Mar, 09:05",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "Yes. I started with Java to build test harnesses and I’ve continued to use it for reliability engineering tasks."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I have full authorization to live and work anywhere in the EU."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I implemented a load-testing script to identify bottlenecks, focusing on optimizing queries and caching to reduce latency."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I prioritize reliability and test coverage early on, incorporating continuous integration to catch issues before production."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I always push for automated test coverage reports in pull requests, highlighting areas that need additional scrutiny."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I led a DevOps transition, setting up a CI/CD pipeline that automated tests, builds, and deployments across multiple environments."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I use thread pools and advanced concurrency utilities, ensuring the code is well-tested under stress for reliability."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I like Spring Boot for microservices, plus JUnit and Mockito for thorough testing coverage in CI/CD environments."
            }
          ]
        },
        {
          photo:
            "https://plus.unsplash.com/premium_photo-1672239496290-5061cfee7ebb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Bjarne Stroustrup",
          email: "bjarne@site.com",
          summary:
            "Deep knowledge of C++. Prefers memory-efficient solutions. Advocates strongly typed frameworks.",
          keyword1: "Memory Management",
          keyword2: "Strong Typing",
          rating: "89",
          rationale:
            "Advanced C++ memory handling, strongly typed frameworks advocate.",
          applied: "03 Mar, 11:30",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "My focus has been on C++. My Java usage is sporadic, so it’s definitely under four years."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I hold dual citizenship, so I can work throughout the EU without any issues."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I revamped a C++ module to handle dynamic allocations more efficiently, reducing memory leaks and fragmentation."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I enforce strong typing to catch errors at compile time and carefully consider RAII patterns for resource management."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I emphasize readability, using templates sparingly and reviewing each commit for consistent style and memory safety."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I guided a team rewriting legacy C code into modern C++, ensuring memory safety and type consistency throughout."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "Given my C++ background, I’d likely map my concurrency ideas onto Java’s Thread or Executor model, but I keep it minimal."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I haven’t worked deeply with Java frameworks, but from what I’ve seen, Spring’s structured approach is appealing for enterprise use."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1610186594416-2c7c0131e35d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Guido van Rossum",
          email: "guido@site.com",
          summary:
            "Python guru. Simplifies complex logic. Great at balancing readability and performance.",
          keyword1: "Pythonic Code",
          keyword2: "Code Readability",
          rating: "87",
          rationale:
            "Balances code readability with performance, strong Python fundamentals.",
          applied: "04 Mar, 10:00",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "I’m primarily a Python specialist, so my Java experience is under four years. I’ve only used it in minor cross-language projects."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I’ve lived and worked in the EU for several years now."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I optimized a data pipeline in Python, reducing nested loops by using list comprehensions and generator expressions effectively."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I prefer clean, modular code, employing Pythonic constructs and ensuring each module’s responsibility is clearly defined."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I champion PEP 8 guidelines, focusing on readability, and encourage incremental improvements for better maintainability."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I led a Python web framework migration, teaching best practices while implementing performance enhancements for production."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I’m more used to Python’s threading and async, but if I use Java, I rely on the concurrency libraries to manage threads effectively."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I’ve had some exposure to Spring Boot from Python-Java integration projects; it’s well-documented and has a large community."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1565991120485-4913d43ff5e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Jane Gosling",
          email: "jane@site.com",
          summary:
            "Solid Java developer. Familiar with microservices but only 4 years work experience.",
          keyword1: "Microservices",
          keyword2: "System Integration",
          rating: "74",
          rationale:
            "Needs more experience in Java frameworks and large scale systems.",
          applied: "04 Mar, 14:50",
          verified: false,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "Yes, I’m right at the four-year mark, mainly working on microservices in Java."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "I do. I got it right before starting my last position with a European-based team."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I implemented a microservice for user authentication, focusing on efficient data queries and secure session management."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I use a layered approach, ensuring separation of concerns, although I'm still exploring advanced design patterns in Java."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I submit smaller pull requests for clarity and appreciate feedback from more experienced contributors on Java frameworks."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I haven't formally led a project yet, but I did manage task assignments in a small team to deliver a microservice feature."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I rely on the concurrency utilities built into the JDK, plus a layered approach to limit race conditions and deadlocks."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "So far, I’ve used Spring Boot for microservices. I appreciate its annotation-based configuration and quick startup for small services."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1548142542-c53707f8b05b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Tina Berners-Lee",
          email: "tina@site.com",
          summary:
            "Thought leader in semantic technologies. Mentors across multiple code bases.",
          keyword1: "Semantic Web",
          keyword2: "Cross-team Mentorship",
          rating: "97",
          rationale:
            "Thought leader with semantic web, top-tier cross-team mentor.",
          applied: "05 Mar, 09:45",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "I’ve dabbled in Java for semantic web integrations, but my primary domain has been RDF and other semantic standards."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I’m fully eligible to work within the EU, having done so for several multi-national projects."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I developed a semantic data model to link disparate data sets, improving retrieval efficiency using advanced indexing structures."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I prioritize ontology-driven design, ensuring data interoperability across different systems for a future-proof architecture."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I mentor contributors on semantic standards and propose structured approaches for code reuse across projects."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I spearheaded an organization-wide initiative to adopt semantic annotations, guiding multiple teams through the integration process."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I’ve usually built concurrency logic in RDF layers, but if I code in Java, I ensure any concurrency approach respects data consistency."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I’ve collaborated with teams using Apache Jena for semantic data in Java, as it fits well with Linked Data and RDF workflows."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1500649297466-74794c70acfc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Brendan Eich",
          email: "beich@site.com",
          summary:
            "Solo-entreprenur now looking for a bigger team to grow in. Lacks proven team experience and some best practices.",
          keyword1: "JavaScript",
          keyword2: "Rapid Prototyping",
          rating: "79",
          rationale:
            "Good at rapid prototyping but has mainly worked on solo projects.",
          applied: "05 Mar, 13:15",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "Not at all. I’ve predominantly worked with JavaScript. My Java experience is more experimental than production-level."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I do, and I’m open to relocating for the right team environment."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I quickly built a JavaScript prototype for a web app, focusing on user interface logic and rapid feature iteration."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I prioritize speed and a flexible foundation, though I'm open to learning more robust architecture practices with a bigger team."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "Previously, I mostly worked solo. I'd appreciate structured feedback to adapt to a larger dev environment and best practices."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I led my own startup, handling most tech decisions myself, but I'm eager to learn from larger, team-oriented settings now."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I’m not deeply versed in Java concurrency. I’d probably rely on built-in concurrency libraries and expert guidance."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "So far, I’ve only tinkered with Spring Boot in personal experiments. It seemed straightforward for building quick prototypes."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1554651802-57f1d69a4944?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Robert Pike",
          email: "robert@site.com",
          summary:
            "He is focused on Go language features. He is comfortable with concurrency models. He is great at code readability.",
          keyword1: "Concurrency",
          keyword2: "Go Language",
          rating: "90",
          rationale:
            "Deep concurrency knowledge in Go, fosters highly readable code.",
          applied: "06 Mar, 10:30",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "I mostly use Go, but I do have about two years in Java from earlier roles, so not quite four."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I obtained a work permit last year and I’ve been working remotely for an EU-based startup."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I developed a parallel processing pipeline in Go, leveraging channels and goroutines to handle large data sets efficiently."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I consider concurrency early on, structuring code to maximize performance while keeping the design straightforward and readable."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I push for clear naming conventions and thorough documentation, ensuring new contributors can easily grasp concurrency aspects."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I led a Go microservice refactoring effort, focusing on concurrency safety and improved module organization."
            },
            // NEW questions (Java-related):
            {
              question: "How do you approach concurrency in Java?",
              answer: "I’d adapt the same concurrency principles I use in Go, but rely on Java’s synchronized blocks and concurrency packages."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I’m less familiar with the Java ecosystem, but from what I’ve seen, frameworks like Spring can streamline microservice setups."
            }
          ]
        },
        {
          photo:
            "https://images.unsplash.com/photo-1458696352784-ffe1f47c2edc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80",
          name: "Ken Thompson",
          email: "kenthompson@site.com",
          summary:
            "UNIX pioneer. Skilled in system-level code. Advocates minimalism in design and codebase structure.",
          keyword1: "System-level Programming",
          keyword2: "Minimalist Design",
          rating: "93",
          rationale:
            "Pioneered Unix systems, minimalistic design approach stands out.",
          applied: "06 Mar, 15:00",
          verified: true,
          interview: [
            // NEW questions:
            {
              question: "Do you have at least 4 years of experience in Java development?",
              answer: "I’m more of a system-level programmer, so my Java exposure doesn’t reach four years."
            },
            {
              question: "Do you have a valid work permit in the EU?",
              answer: "Yes, I maintain a valid EU work permit and have collaborated with EU research teams."
            },
            // ORIGINAL questions:
            {
              question: "Tell me about a recent coding challenge or project that tested your algorithmic skills.",
              answer: "I worked on refining a file system module, streamlining data structures to boost efficiency at the system level."
            },
            {
              question: "How do you approach system design or architecture decisions in your projects?",
              answer: "I strip everything down to essential components, focusing on simplicity and reliability in each layer."
            },
            {
              question: "How do you handle code reviews and collaboration, especially in open-source environments?",
              answer: "I encourage small, focused commits that reflect a single change, keeping the codebase easy to navigate and maintain."
            },
            {
              question: "Can you describe a time you took on a leadership role in a technical project?",
              answer: "I guided a team modernizing an old UNIX-based tool, ensuring minimal code bloat and maximum performance."
            },
            {
              question: "How do you approach concurrency in Java?",
              answer: "I’d rely on well-established patterns like Lock objects or semaphores, but only if minimalism is preserved in the code."
            },
            {
              question: "Which Java frameworks or libraries do you prefer, and why?",
              answer: "I’m not deeply engaged with Java frameworks, but I find the standard library sufficient for simple tasks when I do use Java."
            }
          ]
        }
      ]
    }    
  };


  const [currentIndustry, setCurrentIndustry] = useState<string>("healthcare");
  const [prompt, setPrompt] = useState("");
  const [queryAnswer, setQueryAnswer] = useState({ answer: "" });
  const [isLoadingQueryAnswer, setIsLoadingQueryAnswer] = useState(false);


  // Sorting states
  const [sortBy, setSortBy] = useState<"name" | "rating">("name");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  // Helper to handle sorting
  const handleSort = (column: "name" | "rating") => {
    if (sortBy === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  const text = textData[currentIndustry];

  // Generate the rows for the current industry
  const rows = useMemo(() => {
    const arr = [...text.rows];
    if (sortBy === "name") {
      arr.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortBy === "rating") {
      arr.sort((a, b) => parseInt(a.rating, 10) - parseInt(b.rating, 10));
    }
    if (sortDirection === "desc") {
      arr.reverse();
    }
    return arr;
  }, [text, sortBy, sortDirection]);

  const askWorklens = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setIsLoadingQueryAnswer(true);

      // candidateData is the 'rows' array you already compute
      const candidateData = rows;
      const body = JSON.stringify({ candidateData, prompt });

      const response = await fetch(`${Settings.API_URL}/demo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: body,
      });

      if (!response.ok || !response.body) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      // Clear any existing text
      setQueryAnswer({ answer: "" });

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });

        // Append streamed chunk to state
        setQueryAnswer((prev) => ({
          answer: prev.answer + chunk
        }));
      }
    } catch (error) {
      console.error('Error during streaming:', error);
    } finally {
      setIsLoadingQueryAnswer(false);
    }
  }

  return (
    <>
      <ModalDemoIntro
        isOpened={isModalIntroOpened}
        onClose={closeModalIntro}
      />
      <ModalDemoSynch
        isOpened={isModalSynchOpened}
        onClose={closeModalSynch}
      />
      <ModalDemoInterview
        isOpened={isModalInterviewOpened}
        onClose={closeModalInterview}
        interview={currentInterview}
      />
      <div className="max-w-[1200px] px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          <div className="mx-auto inline-flex rounded-lg shadow-sm mb-4">
            {Object.entries(textData).map(([industryKey, industry]) => (
              <button
                key={industryKey}
                type="button"
                onClick={() => setCurrentIndustry(industryKey)}
                className={`py-2 px-3 inline-flex justify-center items-center gap-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 shadow-sm outline-none disabled:opacity-50 disabled:pointer-events-none ${currentIndustry === industryKey
                  ? "bg-blue-600 text-white dark:border-neutral-700"
                  : "bg-white text-gray-800 dark:bg-neutral-900 dark:border-neutral-700 dark:text-white"
                  }`}
              >
                {industry.title}
              </button>
            ))}
          </div>

          {/* Metric Cards */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-6">
            {/* Card 1 */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                    {text.metricOneLabel}
                  </p>
                  <div className="hs-tooltip">
                    <div className="hs-tooltip-toggle">
                      <svg
                        className="shrink-0 size-4 text-gray-500 dark:text-neutral-500"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx={12} cy={12} r={10} />
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                        <path d="M12 17h.01" />
                      </svg>
                      <span
                        className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
                        role="tooltip"
                      >
                        {text.metricOneTooltip}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {text.metricOneCount}
                  </h3>
                  <span className="flex items-center gap-x-1 text-green-600">
                    <svg
                      className="inline-block size-4 self-center"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="22 7 13.5 15.5 8.5 10.5 2 17" />
                      <polyline points="16 7 22 7 22 13" />
                    </svg>
                    <span className="inline-block text-sm">
                      {text.metricOneDiff}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/* End Card 1 */}

            {/* Card 2 */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                    {text.metricTwoLabel}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {text.metricTwoCount}
                  </h3>
                </div>
              </div>
            </div>
            {/* End Card 2 */}

            {/* Card 3 */}
            <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
              <div className="p-4 md:p-5">
                <div className="flex items-center gap-x-2">
                  <p className="text-xs uppercase tracking-wide text-gray-500 dark:text-neutral-500">
                    {text.metricThreeLabel}
                  </p>
                </div>
                <div className="mt-1 flex items-center gap-x-2">
                  <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-neutral-200">
                    {text.metricThreeCount}
                  </h3>
                  <span className="flex items-center gap-x-1 text-red-600">
                    <svg
                      className="inline-block size-4 self-center"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="22 17 13.5 8.5 8.5 13.5 2 7" />
                      <polyline points="16 17 22 17 22 11" />
                    </svg>
                    <span className="inline-block text-sm">
                      {text.metricThreeDiff}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* End Grid of Metric Cards */}

          {/* Search field example */}
          <div className="flex items-center w-full">
            {/* Search Form (Left-Aligned) */}
            <form onSubmit={askWorklens} className="flex items-center rounded-lg shadow-sm flex-none relative">
              <span className="absolute flex size-3 left-0 top-0">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex size-3 rounded-full bg-sky-500"></span>
              </span>
              <input
                type="text"
                placeholder="Ask anything about the interview & applicants"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="py-2 px-4 min-w-[340px] block border border-gray-200 shadow-sm rounded-s-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 text-gray-500 dark:text-neutral-400 flex-shrink-0 flex-grow-0"
              />
              <button
                type="submit"
                className="h-[40px] w-[2.875rem] shrink-0 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-blue-600 text-white"
              >
                {isLoadingQueryAnswer ? (
                  <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full"></div>
                ) : (
                  <Search className="w-4 h-4 shrink-0" />
                )}
              </button>
            </form>

            {/* Export Button (Aligned to the Right) */}
            <button
              type="button"
              className="ml-auto h-[40px] px-4 flex items-center justify-center text-sm font-semibold rounded-lg border border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-100"
              onClick={() => setIsModalSynchOpened(!isModalSynchOpened)}
            >
              Synch candidates with ATS
            </button>
          </div>

          <p className="text-gray-500 dark:text-neutral-400 max-w-[620px] flex-grow">
            {queryAnswer.answer && (
              <ReactMarkdown className="text-sm prose dark:prose-invert">
                {queryAnswer.answer}
              </ReactMarkdown>
            )}
          </p>
          {/* End Search field example */}

          {/* Card with Table */}
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
                  {/* Header */}
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                        {text.tableTitle}
                      </h2>
                    </div>
                  </div>
                  {/* End Header */}

                  {/* Table */}
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                    <thead className="bg-gray-50 dark:bg-neutral-800">
                      <tr>
                        {/* Name col - sortable */}
                        <th
                          scope="col"
                          className="px-6 py-3 text-start cursor-pointer"
                          onClick={() => handleSort("name")}
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                              {text.colName}
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                              {text.colSummary}
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start relative">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                              {text.colViewInterview}
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                              {text.colVerified}
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                              {text.colKeywords}
                            </span>
                          </div>
                        </th>
                        {/* Rating col - sortable */}
                        <th
                          scope="col"
                          className="px-6 py-3 text-start cursor-pointer"
                          onClick={() => handleSort("rating")}
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                              {text.colRating}
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200">
                              {text.colApplied}
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                      {rows.map((row: any, idx) => (
                        <tr key={idx}>
                          <td className="whitespace-nowrap">
                            <div className="px-6 py-3 text-start">
                              <div className="flex items-center gap-x-3">
                                <img
                                  className="inline-block h-9 w-9 rounded-full"
                                  src={row.photo}
                                  alt="Avatar"
                                />
                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                                    {row.name}
                                  </span>
                                  <span className="block text-sm text-gray-500 dark:text-neutral-500">
                                    {row.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="px-6 py-3">
                              <p className="block text-sm text-gray-500 dark:text-neutral-500">
                                {row.summary}
                              </p>
                            </div>
                          </td>
                          <td className="relative">
                            <div className="px-6 py-3">
                              <p className="text-sm text-gray-800 dark:text-neutral-200 relative">
                                {idx === 0 && (
                                  <span className="absolute flex size-2 left-0 top-0">
                                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-pink-400 opacity-75"></span>
                                    <span className="relative inline-flex size-2 rounded-full bg-pink-500"></span>
                                  </span>
                                )}
                                <a
                                  className="flex items-center justify-center h-7 w-7 rounded-full bg-blue-600"
                                  onClick={() => {
                                    setCurrentInterview(row.interview || []);
                                    setIsModalInterviewOpened(true);
                                  }}>
                                  <NotebookText className="h-4 w-4 cursor-pointer text-white" />
                                </a>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="px-6 py-3">
                              <p className="text-sm text-gray-800 dark:text-neutral-200 text-center">
                                {row.verified ? (<Check className="w-4 h-4" />) : (<></>)}
                              </p>
                            </div>
                          </td>
                          <td className="whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="mb-2 mr-2 py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                                {row.keyword1}
                              </span><br />
                              <span className="mb-2 py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                                {row.keyword2}
                              </span>
                            </div>
                          </td>
                          <td className="whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500 dark:text-neutral-500">
                                  {row.rating}
                                </span>
                                <span className="cursor-pointer text-sm max-w-40 tooltip-container-badge inline-block text-gray-800 dark:text-neutral-200">
                                  <Tooltip
                                    className=""
                                    id="tooltip-interview-summary"
                                    style={{ zIndex: 99 }}
                                    place="left"
                                  />
                                  <Info
                                    className="w-4 h-4 tooltip text-gray-400"
                                    data-tooltip-id="tooltip-interview-summary"
                                    data-tooltip-content={'Rationale: ' + row.rationale}
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500 dark:text-neutral-500">
                                {row.applied}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* End Table */}
                </div>
              </div>
            </div>
          </div>
          {/* End Card with Table */}
        </div>
      </div >
      <Footer />
    </>
  );
};

export default Demo;
