import { useAuth0 } from '@auth0/auth0-react';
import { Fragment, useEffect, useState } from 'react';
import { OrganizationApplicationGet } from '../../types';
import Settings from '../../Settings';
import { Bot, Paperclip } from 'lucide-react';
import { upperCased } from '../../util';
import { useParams } from 'react-router-dom';


const Application: React.FC = () => {

  const { getAccessTokenSilently } = useAuth0();
  const { applicationUuid } = useParams();
  const [application, setApplication] = useState<OrganizationApplicationGet>();
  const [isLoadingApplication, setIsLoadingApplication] = useState(true);

  useEffect(() => {
    const getInterview = async () => {
      const token = await getAccessTokenSilently();

      try {
        const response = await fetch(
          `${Settings.API_URL}/organizations/applications?uuid=${applicationUuid}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }
        );

        if (response.ok) {
          const interviewResponse = await response.json();
          setApplication(interviewResponse);
        }
      } catch (error) {
        console.error('Error fetching interview:', error);
      } finally {
        setIsLoadingApplication(false);
      }
    };

    getInterview();
  }, [applicationUuid, getAccessTokenSilently]);

  const getAttachment = async (interviewUuid: string, attachmentUuid: string) => {
    const token = await getAccessTokenSilently();
    
    const attachmentResponse = await fetch(Settings.API_URL + `/organizations/applications/attachments?interviewUuid=${interviewUuid}&attachmentUuid=${attachmentUuid}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    const attachmentURL = await attachmentResponse.json();
    window.open(attachmentURL)
  };

  return (
    <div className="flex flex-col">
      <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
          <div>
            <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
              Interview
              {isLoadingApplication && (
                <div
                  className="ml-2 animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                  role="status"
                  aria-label="loading"
                ></div>
              )}
            </h2>
          </div>
        </div>
        {!isLoadingApplication && application && (
          <div className="px-6 py-4 ">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                {upperCased(application.candidate.firstname + ' ' + application.candidate.lastname)}
              </h2>
              <p className="text-gray-600 dark:text-neutral-400">
                {application.interviewTitle}
              </p>
            </div>
            <div className="max-w-[40rem] mx-auto">
              <div className="container">
                <ul className="space-y-5">
                  {application.questionsAnswers?.map((qaItem, index) => (
                    <Fragment key={index}>
                      <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                        <div className="inline-block size-9 rounded-full">
                          <Bot className="h-10 w-10 text-black dark:text-white" />
                        </div>
                        <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                          <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                            {qaItem.questionText}
                          </p>
                        </div>
                      </li>
                      <li className="flex ms-auto gap-x-2 sm:gap-x-4">
                        <div className="grow text-end space-y-3">
                          <div className="inline-block bg-blue-600 rounded-2xl p-4 shadow-sm">
                            <p className="text-sm text-white">{qaItem.answer}</p>
                            {qaItem.attachmentUuid && (
                              <a 
                                className="text-sm cursor-pointer block text-white flex" 
                                target="_blank" 
                                onClick={() => getAttachment(application.interviewUuid, qaItem.attachmentUuid!)}>
                                  <Paperclip className="w-4 h-4" />&nbsp; Load attachment
                                </a>
                            )}
                          </div>
                        </div>
                        <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                          <span className="text-sm font-medium text-white leading-none">
                            User
                          </span>
                        </span>
                      </li>
                      {qaItem.followUps?.map((followUp, fIndex) => (
                        <Fragment key={fIndex}>
                          <li className="max-w-lg flex gap-x-2 sm:gap-x-4 me-11">
                            <div className="inline-block size-9 rounded-full">
                              <Bot className="h-10 w-10 text-black dark:text-white" />
                            </div>
                            <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3 dark:bg-neutral-900 dark:border-neutral-700">
                              <p className="mb-1.5 text-sm text-gray-800 dark:text-white">
                                {followUp.question}
                              </p>
                            </div>
                          </li>
                          <li className="flex ms-auto gap-x-2 sm:gap-x-4">
                            <div className="grow text-end space-y-3">
                              <div className="inline-block bg-blue-600 rounded-2xl p-4 shadow-sm">
                                <p className="mb-1.5 text-sm text-white">{followUp.answer}</p>
                              </div>
                            </div>
                            <span className="shrink-0 inline-flex items-center justify-center size-[38px] rounded-full bg-gray-600">
                              <span className="text-sm font-medium text-white leading-none">
                                User
                              </span>
                            </span>
                          </li>
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
};

export default Application;