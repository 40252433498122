import { useState } from "react";
import { Loader2 } from "lucide-react";
import Settings from "../Settings";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

interface OrganizationResponse {
  organization: {
    id: string;
    name: string;
  };
  adminUser: {
    email: string;
    userId: string;
  };
}

const CreateOrganization: React.FC = () => {

  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();

  const [organizationName, setOrganizationName] = useState("");
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordRepeated, setUserPasswordRepeated] = useState("");
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [isCreatingOrganization, setIsCreatingOrganization] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [createdOrganization, setCreatedOrganization] = useState<OrganizationResponse | null>(null);

  const createOrganization = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!hasAcceptedTerms)
      setError("You must accept the Terms and conditions")

    setIsCreatingOrganization(true);
    setError(null);

    try {
      const response = await fetch(`${Settings.API_URL}/organizations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ organizationName, userFirstname, userLastname, userEmail, userPassword, userPasswordRepeated }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return setError(errorData.error);
      }

      const data: OrganizationResponse = await response.json();
      setCreatedOrganization(data);
    } catch (error) {
      console.log(error);
      setError("Failed to create organization. Please try again.");
    } finally {
      setIsCreatingOrganization(false);
    }
  };

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setUserPassword(password);

    if (passwordRegex.test(password))
      setError("Password must contain lowercase (a-z), uppercase (A-Z), numbers (0-9) and a special character (!@#$%^&*)");
  };

  const handlePasswordRepeatedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setUserPasswordRepeated(password);

    if (userPassword !== password)
      setError("Passwords do not match");
    else
      setError(null);
  };

  const handleChangeHasAcceptedTerms = () => {
    setHasAcceptedTerms(!hasAcceptedTerms);
  };

  return (
    <main className="flex-grow">
      <div aria-hidden="true" className="!w-full flex absolute -top-96 start-1/2 transform -translate-x-1/2">
        <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem] dark:from-violet-900/50 dark:to-purple-900" />
        <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem] dark:from-indigo-900/70 dark:via-indigo-900/70 dark:to-blue-900/70" />
      </div>
      <Header />

      <>
        <div className="relative">
          <div className="max-w-[1240px] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid items-start md:grid-cols-2 gap-8 lg:gap-12">
              <div className="mt-4 max-w-2xl">
                <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl text-gray-800 dark:text-white">
                  {t('title')}<br />
                  <span className="whitespace-pre-wrap text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-500 text-3xl md:text-4xl">
                    {t('subtitle_alt1')}
                  </span>
                  <br />
                </h1>
                <p className="text-lg text-gray-600 dark:text-white text-neutral-700 my-2">
                  {t('main_description')}
                </p>
                <span
                  data-tooltip-id="tooltip-gdpr"
                  data-tooltip-content={t('tooltip_gdpr')}
                  className="z-100 inline-flex items-center gap-x-1.5 py-1 px-3 rounded-md text-xs font-medium border border-gray-200 bg-white text-gray-800 shadow-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-white mb-4"
                >
                  {t('gdpr_compliant')}
                  <Tooltip
                    id="tooltip-gdpr"
                    className="max-w-[200px]"
                  />
                </span>
              </div>

              <div>
                {!createdOrganization ? (
                  <form className="max-w-sm mx-auto" onSubmit={createOrganization}>
                    <div className="lg:max-w-lg lg:mx-auto lg:me-0 ms-auto">
                      <div className="p-4 sm:p-7 flex flex-col bg-white rounded-2xl shadow-lg dark:bg-neutral-900">
                        <div className="text-center">
                          <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                            {t('start_your_trial')}
                          </h1>
                          <p className="mt-2 text-sm text-gray-600 dark:text-white text-neutral-700">
                            {t('already_have_an_account')}&nbsp;
                            <a
                              className="text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
                              href="#"
                              onClick={() => loginWithRedirect()}
                            >
                              {t('sign_in_here')}
                            </a>
                          </p>

                          <div className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-neutral-500 dark:before:border-neutral-700 dark:after:border-neutral-700">
                            {t('sign_up')}
                          </div>

                          <div className="grid grid-cols-2 gap-4">
                            <div className="relative">
                              <input
                                type="text"
                                id="userFirstname"
                                className="
                                  peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:text-white text-neutral-700 dark:focus:ring-neutral-600
                                  focus:pt-6
                                  focus:pb-2
                                  [&:not(:placeholder-shown)]:pt-6
                                  [&:not(:placeholder-shown)]:pb-2
                                  autofill:pt-6
                                  autofill:pb-2"
                                placeholder={t('input_firstname')}
                                value={userFirstname}
                                onChange={(e) => setUserFirstname(e.target.value)}
                              />
                              <label
                                htmlFor="userFirstname"
                                className="
                                  absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] text-gray-400 peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                  peer-focus:scale-90
                                  peer-focus:translate-x-0.5
                                  peer-focus:-translate-y-1.5
                                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                                  peer-[:not(:placeholder-shown)]:scale-90
                                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                              >
                                {t('input_firstname_label')}
                              </label>
                            </div>
                            <div className="relative">
                              <input
                                type="text"
                                id="userLastname"
                                className="
                                  peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white text-neutral-700 dark:focus:ring-neutral-600
                                  focus:pt-6
                                  focus:pb-2
                                  [&:not(:placeholder-shown)]:pt-6
                                  [&:not(:placeholder-shown)]:pb-2
                                  autofill:pt-6
                                  autofill:pb-2"
                                placeholder={t('input_lastname')}
                                value={userLastname}
                                onChange={(e) => setUserLastname(e.target.value)}
                              />
                              <label
                                htmlFor="hs-hero-signup-form-floating-input-last-name"
                                className="
                                  absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] text-gray-400 peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                  peer-focus:scale-90
                                  peer-focus:translate-x-0.5
                                  peer-focus:-translate-y-1.5
                                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                                  peer-[:not(:placeholder-shown)]:scale-90
                                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                              >
                                {t('input_lastname_label')}
                              </label>
                            </div>
                          </div>

                          <div className="grid gap-4 mt-4">
                            <div className="relative">
                              <input
                                type="text"
                                id="organizationName"
                                className="
                                  peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white text-neutral-700 dark:focus:ring-neutral-600
                                  focus:pt-6
                                  focus:pb-2
                                  [&:not(:placeholder-shown)]:pt-6
                                  [&:not(:placeholder-shown)]:pb-2
                                  autofill:pt-6
                                  autofill:pb-2"
                                placeholder={t('input_organization_name')}
                                value={organizationName}
                                onChange={(e) => setOrganizationName(e.target.value)}
                              />
                              <label
                                htmlFor="organizationName"
                                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] text-gray-400 peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                  peer-focus:scale-90
                                  peer-focus:translate-x-0.5
                                  peer-focus:-translate-y-1.5
                                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                                  peer-[:not(:placeholder-shown)]:scale-90
                                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                              >
                                {t('input_organization_name_label')}
                              </label>
                            </div>

                            <div className="relative">
                              <input
                                type="email"
                                id="userEmail"
                                className="
                                  peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white text-neutral-700 dark:focus:ring-neutral-600
                                  focus:pt-6
                                  focus:pb-2
                                  [&:not(:placeholder-shown)]:pt-6
                                  [&:not(:placeholder-shown)]:pb-2
                                  autofill:pt-6
                                  autofill:pb-2"
                                placeholder={t('input_email')}
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                              />
                              <label
                                htmlFor="userEmail"
                                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] text-gray-400 peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                  peer-focus:scale-90
                                  peer-focus:translate-x-0.5
                                  peer-focus:-translate-y-1.5
                                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                                  peer-[:not(:placeholder-shown)]:scale-90
                                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                              >
                                {t('input_email_label')}
                              </label>
                            </div>

                            <div className="relative">
                              <input
                                type={isPasswordVisible ? 'text' : 'password'}
                                id="userPassword"
                                className="
                                  peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white text-neutral-700 dark:focus:ring-neutral-600
                                  focus:pt-6
                                  focus:pb-2
                                  [&:not(:placeholder-shown)]:pt-6
                                  [&:not(:placeholder-shown)]:pb-2
                                  autofill:pt-6
                                  autofill:pb-2"
                                placeholder={t('input_password')}
                                value={userPassword}
                                onChange={(e) => handlePasswordChange(e)}
                                autoComplete="off"
                              />
                              <label
                                htmlFor="userPassword"
                                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] text-gray-400 peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                  peer-focus:scale-90
                                  peer-focus:translate-x-0.5
                                  peer-focus:-translate-y-1.5
                                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                                  peer-[:not(:placeholder-shown)]:scale-90
                                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                              >
                                {t('input_password_label')}
                              </label>
                              <button type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)} className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                                <svg className="shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" strokeLinejoin="round">
                                  <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                  <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                  <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                  <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                                  <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                  <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                                </svg>
                              </button>
                            </div>

                            <div className="relative">
                              <input
                                type={isPasswordVisible ? 'text' : 'password'}
                                id="userPasswordRepeated"
                                className="
                                  peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white text-neutral-700 dark:focus:ring-neutral-600
                                  focus:pt-6
                                  focus:pb-2
                                  [&:not(:placeholder-shown)]:pt-6
                                  [&:not(:placeholder-shown)]:pb-2
                                  autofill:pt-6
                                  autofill:pb-2"
                                placeholder={t('input_password_repeated')}
                                value={userPasswordRepeated}
                                onChange={(e) => handlePasswordRepeatedChange(e)}
                                autoComplete="off"
                              />
                              <label
                                htmlFor="userPasswordRepeated"
                                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] text-gray-400 peer-disabled:opacity-50 peer-disabled:pointer-events-none
                                  peer-focus:scale-90
                                  peer-focus:translate-x-0.5
                                  peer-focus:-translate-y-1.5
                                  peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                                  peer-[:not(:placeholder-shown)]:scale-90
                                  peer-[:not(:placeholder-shown)]:translate-x-0.5
                                  peer-[:not(:placeholder-shown)]:-translate-y-1.5
                                  peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500"
                              >
                                {t('input_password_repeated_label')}
                              </label>
                              <button type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)} className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                                <svg className="shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                  <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                  <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                  <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                  <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22"></line>
                                  <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                  <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3"></circle>
                                </svg>
                              </button>
                            </div>
                          </div>

                          <div className="mt-5 flex items-center">
                            <div className="flex">
                              <input
                                id="hasAcceptedTerms"
                                name="hasAcceptedTerms"
                                type="checkbox"
                                className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                checked={hasAcceptedTerms}
                                onChange={handleChangeHasAcceptedTerms}
                              />
                            </div>
                            <div className="ms-3">
                              <label
                                htmlFor="hasAcceptedTerms"
                                className="text-sm text-gray-400"
                              >
                                {t('terms_conditions_accept')}&nbsp;
                                <Link
                                  to="/agreement-organization" className="text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
                                  target="_blank"
                                  onClick={() => window.scrollTo(0, 0)}>
                                  {t('terms_conditions_accept_link')}
                                </Link>
                              </label>
                            </div>
                          </div>
                          <div className="mt-5">
                            {error && <p className="my-2 text-sm text-red-600">{error}</p>}
                            <button
                              type="submit"
                              disabled={isCreatingOrganization}
                              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                              {isCreatingOrganization ? (
                                <>
                                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                  Setting up...
                                </>
                              ) : (
                                t('create_account')
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="max-w-sm mx-auto">
                    <h2 className="text-2xl text-gray-800 dark:text-neutral-200 font-bold mb-4">Welcome to Worklens!</h2>
                    <p className="dark:text-white text-neutral-700 mb-8">
                      You've successfully set up your organization.<br />
                      Login using the email and password you just created.
                    </p>
                    <button
                      type="submit"
                      onClick={() => loginWithRedirect()}
                      className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                      Login to Worklens
                    </button>

                  </div>
                )}
              </div>
            </div>
            <div className="mt-6 md:mt-12 py-3 flex items-center text-sm text-gray-800 gap-x-1.5 after:flex-1 after:border-t after:border-gray-200 after:ms-6 text-gray-400 dark:after:border-neutral-700">
              <span className="font-semibold bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent dark:from-blue-400 dark:to-violet-400"></span>
            </div>
          </div>
        </div>
      </>

      <Footer />

    </main>
  );
};

export default CreateOrganization;