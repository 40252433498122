import * as React from 'react';


interface ModalDemoIntroProps {
  isOpened: boolean;
  onClose: () => void;
}

const ModalDemoIntro: React.FC<ModalDemoIntroProps> = ({
  isOpened,
  onClose
}) => {

  return (
    <>
      <div
        className={`hs-overlay size-full fixed top-0 start-0 z-[80] pointer-events-none ${isOpened ? 'open' : 'hidden'
          }`}
      >
        <div className="hs-overlay-open:mt-7 p-4 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-4xl lg:w-full m-3 lg:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
         
            {/* Body */}
            <div className="px-2 py-8">
              <>
                {/* Hero */}
                <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
                  {/* Grid */}
                  <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-top">
                    <div>
                      <h1 className="block text-3xl font-bold text-gray-800 lg:leading-tight dark:text-white">
                        <span className="text-blue-600">Automated screening</span> <br />Better candidates
                      </h1>
                      <p className="mt-3 text-md text-gray-600 dark:text-neutral-400">
                        This is a Worklens demo dashboard showing candidates that have gone through your AI based screening.
                      </p>
                      <p>
                        <ul className="mt-3 text-md text-gray-600 dark:text-neutral-400">
                          <li>✅ Ask about & analyze candidates</li>
                          <li>✅ View candidate interviews</li>
                          <li>✅ View candidate ratings</li>
                        </ul>
                      </p>
                      <div
                        className="mt-4 bg-blue-100 border border-blue-200 text-sm text-blue-800 rounded-lg p-4 dark:bg-blue-800/10 dark:border-blue-900 dark:text-blue-500"
                        role="alert"
                        tabIndex={-1}
                        aria-labelledby="hs-soft-color-info-label"
                      >
                        Features such as using the Worklens AI to design the interview are available if you reach out for a demo.<br /><br />
                        This demo is a simplified version of Worklens, containing only test data.
                      </div>


                      {/* Buttons */}
                      <div className="mt-7 grid gap-3 w-full sm:inline-flex">
                        <a
                          className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                          href="#"
                          onClick={onClose}
                        >
                          Get started
                          <svg
                            className="shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </a>
                      </div>
                    </div>
                    {/* End Col */}
                    <div className="relative ms-4">
                      <img
                        className="rounded-md"
                        src='https://worklens-images.s3.eu-north-1.amazonaws.com/demo-splash-intro.png'
                        alt="Hero Image"
                      />
                      {/* End SVG*/}
                    </div>
                    {/* End Col */}
                  </div>
                  {/* End Grid */}
                </div>
                {/* End Hero */}
              </>

            </div>

          </div>
        </div>
      </div>
      <div 
        onClick={onClose} 
        className={`z-10 hs-overlay-backdrop transition duration fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 dark:bg-neutral-900 ${isOpened ? 'open' : 'hidden'}`}></div>
    </>
  );
};

export default ModalDemoIntro;