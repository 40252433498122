import Footer from "./components/Footer";
import Header from "./components/Header";

const AgreementCandidate: React.FC = () => {

  return (
    <main className="flex-grow">

      <Header />

      <div className="relative overflow-hidden">
        <div className="max-w-[85rem] min-h-[40rem] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-24 text-left">
          <div className="text-white">

            <div className="space-y-4 text-gray-800 dark:text-white">

              <div className="max-w-4xl mx-auto py-12 px-6">
                <h1 className="text-3xl font-bold mb-6">Worklens</h1>
                <h2 className="text-2xl font-semibold mb-4">
                  User Agreement for Job Applicants
                </h2>
                <p className="mb-8">
                  Effective Date: <span className="font-medium">2024-10-31</span>
                </p>
                <hr className="mb-8" />
                <div className="space-y-8">
                  {/* 1. Introduction */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">1. Introduction</h3>
                    <p>
                      This User Agreement (<strong>"Agreement"</strong>) is a legal contract
                      between <strong>Worklens</strong> (
                      <strong>"we," "us,"</strong> or <strong>"our"</strong>) and you, the job
                      applicant (<strong>"you"</strong> or <strong>"your"</strong>). By
                      accessing or using our platform, you agree to be bound by this
                      Agreement.
                    </p>
                  </div>
                  {/* 2. Services */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">2. Services</h3>
                    <p>
                      We provide an online platform that facilitates job interviews and
                      assessments using AI-powered agents (the <strong>"Services"</strong>).
                      The Services are provided to you free of charge.
                    </p>
                  </div>
                  {/* 3. Acceptance of Terms */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">3. Acceptance of Terms</h3>
                    <p>
                      By creating an account or participating in an interview on our platform,
                      you acknowledge that you have read, understood, and agree to be bound by
                      this Agreement. We may update this Agreement from time to time, and you
                      must accept the latest terms to continue using the Services.
                    </p>
                  </div>
                  {/* 4. Privacy and Data Use */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">4. Privacy and Data Use</h3>
                    <ul className="list-disc pl-5 space-y-2 mt-4">
                      <li>
                        <strong>Data Collection</strong>: We collect personal information you
                        provide during the registration process and throughout your use of the
                        Services.
                      </li>
                      <li>
                        <strong>Data Use</strong>: We use your data to facilitate interviews,
                        improve our Services, and for product development purposes.
                      </li>
                      <li>
                        <strong>Data Sharing</strong>: We may share your data with prospective
                        employers and as required by law. We do not sell your personal data to
                        third parties.
                      </li>
                      <li>
                        <strong>Data Storage</strong>: All personal data is stored within the
                        European Union in compliance with the General Data Protection
                        Regulation (GDPR).
                      </li>
                    </ul>
                  </div>
                  {/* 5. User Responsibilities */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">5. User Responsibilities</h3>
                    <p>
                      You agree to provide accurate and complete information during
                      registration and use of the Services. You are responsible for
                      maintaining the confidentiality of your account credentials.
                    </p>
                  </div>
                  {/* 6. Prohibited Conduct */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">6. Prohibited Conduct</h3>
                    <p>You agree not to engage in any of the following activities:</p>
                    <ul className="list-disc pl-5 space-y-2 mt-4">
                      <li>Providing false or misleading information.</li>
                      <li>Impersonating any person or entity.</li>
                      <li>
                        Engaging in any activity that disrupts or interferes with the
                        Services.
                      </li>
                      <li>Violating any applicable laws or regulations.</li>
                    </ul>
                  </div>
                  {/* 7. Intellectual Property Rights */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      7. Intellectual Property Rights
                    </h3>
                    <p>
                      We retain all rights to the content and materials provided through the
                      Services, including but not limited to software, text, images, and
                      graphics. You may not reproduce, distribute, or create derivative works
                      from our content without our express written permission.
                    </p>
                  </div>
                  {/* 8. License to Use Content */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">8. License to Use Content</h3>
                    <p>
                      By submitting content through the Services, you grant us a
                      non-exclusive, royalty-free, worldwide license to use, reproduce,
                      modify, and distribute your content for the purpose of providing and
                      improving the Services.
                    </p>
                  </div>
                  {/* 9. Termination */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">9. Termination</h3>
                    <p>
                      We reserve the right to suspend or terminate your access to the Services
                      at our discretion, without notice, if you violate any terms of this
                      Agreement.
                    </p>
                  </div>
                  {/* 10. Disclaimers */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">10. Disclaimers</h3>
                    <p>
                      The Services are provided on an "as is" and "as available" basis. We
                      make no warranties or representations regarding the accuracy or
                      completeness of the content provided through the Services.
                    </p>
                  </div>
                  {/* 11. Limitation of Liability */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      11. Limitation of Liability
                    </h3>
                    <p>
                      To the fullest extent permitted by law, we shall not be liable for any
                      indirect, incidental, special, or consequential damages arising out of
                      your use of the Services.
                    </p>
                  </div>
                  {/* 12. Amendments to the Agreement */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      12. Amendments to the Agreement
                    </h3>
                    <p>
                      We may update this Agreement from time to time. Any changes will be
                      communicated to you via email or through the platform. You must accept
                      the new terms before continuing to use the Services.
                    </p>
                  </div>
                  {/* 13. Data Protection Rights */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">13. Data Protection Rights</h3>
                    <p>
                      Under GDPR, you have the right to access, rectify, or erase your
                      personal data. You may exercise these rights by contacting us at{" "}
                      <a
                        href="mailto:contact@worklens.ai"
                        className="text-blue-600 hover:underline"
                      >
                        contact@worklens.ai
                      </a>
                      .
                    </p>
                  </div>
                  {/* 14. Governing Law and Jurisdiction */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">
                      14. Governing Law and Jurisdiction
                    </h3>
                    <p>
                      This Agreement is governed by the laws of{" "}
                      <strong>the EU</strong>. Any disputes arising out of
                      this Agreement shall be subject to the exclusive jurisdiction of the
                      courts located in <strong>the EU</strong>.
                    </p>
                  </div>
                  {/* 15. Contact Information */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">15. Contact Information</h3>
                    <p>
                      For any questions or concerns regarding this Agreement or our Services,
                      please contact us at:
                    </p>
                    <p className="mt-4">
                      <strong>Worklens</strong>
                      <br />
                      <a
                        href="mailto:contact@worklens.ai"
                        className="text-blue-600 hover:underline"
                      >
                        contact@worklens.ai
                      </a>
                    </p>
                  </div>
                </div>
                <hr className="my-8" />
                <p className="mt-8">
                  <strong>
                    By using our Services, you acknowledge that you have read, understood, and
                    agree to be bound by this Agreement.
                  </strong>
                </p>
              </div>


            </div>
          </div>
        </div>
      </div>

      <Footer />

    </main>
  );
};

export default AgreementCandidate;
