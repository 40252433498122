import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, ProtectedRouteCandidate, ProtectedRouteUser } from './AuthContext';
import ProfilePageCandidate from './ProfileCandidate';
import AgreementOrganization from './AgreementOrganization';
import LandingPage from './LandingPage';
import LoginCallbackCandidate from './LoginCallbackCandidate';
import Dashboard from './organizations/Dashboard';
import CreateOrganization from './organizations/CreateOrganization';
import LoginPageCandidate from './LoginPageCandidate';
import InterviewPage from './InterviewPage';
import { ToastContainer } from 'react-toastify';
import AgreementCandidate from './AgreementCandidate';
import About from './About';
import Demo from './Demo';
import Pricing from './Pricing';
import Careers from './Careers';


function AppContent() {

  return (
    <div id="main" className="flex flex-col min-h-screen">
      <ToastContainer />
      
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/interview/:organizationName/:title/*" element={<InterviewPage />} />
        <Route path="/login-candidate" element={<LoginPageCandidate />} />
        <Route path="/login-callback-candidate" element={<LoginCallbackCandidate />} />
        <Route path="/create-organization" element={<CreateOrganization />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/careers" element={<Careers />} />
        <Route
          path="/profile-candidate"
          element={
            <ProtectedRouteCandidate>
              <ProfilePageCandidate />
            </ProtectedRouteCandidate>
          }
        />
        <Route
          path="/organization/dashboard/*"
          element={
            <ProtectedRouteUser>
              <Dashboard />
            </ProtectedRouteUser>
          }
        />

        <Route path="/agreement-organization" element={<AgreementOrganization />} />
        <Route path="/agreement-candidate" element={<AgreementCandidate />} />
        <Route path="/about" element={<About />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>

    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;