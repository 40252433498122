import { useEffect, useState, useMemo } from 'react';
import { OrganizationInterviewGet } from '../../types';
import Settings from '../../Settings';
import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';

type OrganizationIntegrationTeamTailor = {
  externalOrganizationId: string;
  created: Date;
  worklensInterviewUuid: string;
  teamTailorJobId: number;
};

interface JobAttributes {
  'apply-button-text': string;
  body: string;
  'end-date': string | null;
  'human-status': string;
  internal: boolean;
  'language-code': string;
  picture: string | null;
  pinned: boolean;
  'start-date': string | null;
  status: string;
  tags: string[];
  title: string;
  'internal-name': string;
  pitch: string;
  'external-application-url': string | null;
  'name-requirement': string;
  'resume-requirement': string;
  'additional-files-requirement': string;
  'cover-letter-requirement': string;
  'phone-requirement': string;
  'created-at': string;
  'updated-at': string;
  'sharing-image-layout': string;
  mailbox: string;
  'remote-status': string;
  currency: string;
  'template-name': string | null;
  'recruiter-email': string;
}

interface Job {
  id: string;
  type: string;
  links: {
    'careersite-job-url': string;
    'careersite-job-apply-url': string;
    'careersite-job-apply-iframe-url': string;
    self: string;
  };
  attributes: JobAttributes;
  relationships: Record<string, unknown>;
}

interface ModalSynchApplicationProps {
  interviewId: number;
  interviewUuid: string;
  isOpened: boolean;     // toggles CSS to show/hide
  onClose: () => void;
}

const ModalSynchApplication: React.FC<ModalSynchApplicationProps> = ({
  interviewId,
  interviewUuid,
  isOpened,
  onClose
}) => {
  const { getAccessTokenSilently } = useAuth0();

  // Track whether we've fetched data for the current interview
  const [hasFetched, setHasFetched] = useState(false);

  // Data states
  const [interview, setInterview] = useState<OrganizationInterviewGet | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [teamTailorJobs, setTeamTailorJobs] = useState<Job[]>([]);
  const [synchedTeamTailorJob, setSynchedTeamTailorJob] =
    useState<OrganizationIntegrationTeamTailor | null>(null);

  // Keep track of the originally synced job so we can detect changes
  const [originalTeamTailorJobId, setOriginalTeamTailorJobId] = useState<number | null>(null);

  // Current selection in the dropdown
  const [selectedTeamTailorJobId, setSelectedTeamTailorJobId] = useState<number | null>(null);

  //
  // Whenever interviewId changes (e.g. user navigates to a different one),
  // we reset so that next time user opens the modal for that new ID, we fetch again.
  //
  useEffect(() => {
    setHasFetched(false);
    setIsLoading(true);

    // Optionally reset data states if you'd like a blank slate on new ID
    setInterview(undefined);
    setTeamTailorJobs([]);
    setSynchedTeamTailorJob(null);
    setOriginalTeamTailorJobId(null);
    setSelectedTeamTailorJobId(null);
  }, [interviewId]);

  //
  // Fetch data only once, when the modal first opens for a given interviewId
  //
  useEffect(() => {
    if (!isOpened || hasFetched) {
      // If the modal is closed, or we've already fetched, do nothing
      return;
    }

    // Mark that we have fetched so it won't happen again for this interviewId
    setHasFetched(true);

    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();

        const [interviewRes, synchedJobRes, jobsRes] = await Promise.all([
          fetch(`${Settings.API_URL}/organizations/interviews?&id=${interviewId}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` },
          }),
          fetch(
            `${Settings.API_URL}/organizations/integrations/teamtailor?interviewUuid=${interviewUuid}`,
            {
              method: 'GET',
              headers: { Authorization: `Bearer ${token}` },
            }
          ),
          fetch(`${Settings.API_URL}/organizations/integrations/teamtailor/jobs`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        if (interviewRes.ok) {
          const interviewData: OrganizationInterviewGet = await interviewRes.json();
          setInterview(interviewData);
        }

        if (synchedJobRes.ok) {
          const synchedJobData: OrganizationIntegrationTeamTailor[] = await synchedJobRes.json();
          if (synchedJobData.length) {
            const firstSyncedJob = synchedJobData[0];
            setSynchedTeamTailorJob(firstSyncedJob);
            setSelectedTeamTailorJobId(firstSyncedJob.teamTailorJobId);
            setOriginalTeamTailorJobId(firstSyncedJob.teamTailorJobId);
          } else {
            // No job previously synced
            setOriginalTeamTailorJobId(null);
          }
        }

        if (jobsRes.ok) {
          const jobsData: Job[] = await jobsRes.json();
          setTeamTailorJobs(jobsData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isOpened, hasFetched, interviewId, interviewUuid, getAccessTokenSilently]);

  //
  // Create sync
  //
  const createSynchTeamTailorJobWithInterview = async (jobId: string) => {
    try {
      const token = await getAccessTokenSilently();
      const data = { teamtailorJobId: jobId, interviewUuid };

      const response = await fetch(`${Settings.API_URL}/organizations/integrations/teamtailor`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Error creating job sync.');
      }
    } catch (error) {
      console.error('Error synching job:', error);
      throw error;
    }
  };

  //
  // Delete sync
  //
  const deleteSynchTeamTailorJobWithInterview = async (jobId: number) => {
    try {
      const token = await getAccessTokenSilently();
      const data = { interviewUuid, teamTailorJobId: jobId };

      const response = await fetch(`${Settings.API_URL}/organizations/integrations/teamtailor`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Error deleting job sync.');
      }
    } catch (error) {
      console.error('Error deleting job sync:', error);
      throw error;
    }
  };

  //
  // Update sync (via PUT)
  //
  const updateSynchTeamTailorJobWithInterview = async (newJobId: string) => {
    try {
      const token = await getAccessTokenSilently();
      if (!synchedTeamTailorJob) {
        throw new Error('No existing job sync found to update.');
      }
      const data = {
        interviewUuid,
        oldTeamTailorJobId: synchedTeamTailorJob.teamTailorJobId,
        newTeamTailorJobId: newJobId,
      };

      const response = await fetch(`${Settings.API_URL}/organizations/integrations/teamtailor`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Error updating job sync.');
      }
    } catch (error) {
      console.error('Error updating job sync:', error);
      throw error;
    }
  };

  //
  // Only enable "Save" if the user changed the dropdown from its original value
  //
  const hasChanges = useMemo(() => {
    return selectedTeamTailorJobId !== originalTeamTailorJobId;
  }, [selectedTeamTailorJobId, originalTeamTailorJobId]);

  //
  // Handle saving changes (create/update/delete the sync)
  //
  const handleSaveChanges = async () => {
    try {
      // If user selected "No job"
      if (!selectedTeamTailorJobId) {
        // But we previously had one => remove it
        if (synchedTeamTailorJob) {
          await deleteSynchTeamTailorJobWithInterview(synchedTeamTailorJob.teamTailorJobId);
          setSynchedTeamTailorJob(null);
          setOriginalTeamTailorJobId(null);
        }
      } else {
        // A job ID is selected
        const newJobId = selectedTeamTailorJobId.toString();

        // If no existing job was synced => create
        if (!synchedTeamTailorJob) {
          await createSynchTeamTailorJobWithInterview(newJobId);
          const newSynchedObject: OrganizationIntegrationTeamTailor = {
            externalOrganizationId: '',
            created: new Date(),
            worklensInterviewUuid: interviewUuid,
            teamTailorJobId: selectedTeamTailorJobId,
          };
          setSynchedTeamTailorJob(newSynchedObject);
          setOriginalTeamTailorJobId(selectedTeamTailorJobId);
        } else {
          // If a different job was previously synced => update
          if (synchedTeamTailorJob.teamTailorJobId !== selectedTeamTailorJobId) {
            await updateSynchTeamTailorJobWithInterview(newJobId);
            const updatedSynchedObject: OrganizationIntegrationTeamTailor = {
              ...synchedTeamTailorJob,
              teamTailorJobId: selectedTeamTailorJobId,
            };
            setSynchedTeamTailorJob(updatedSynchedObject);
            setOriginalTeamTailorJobId(selectedTeamTailorJobId);
          }
          // If the same job is re-selected => do nothing
        }
      }
    } catch (error) {
      console.error('Failed to save changes', error);
    }
  };

  return (
    <>
      <div
        className={`hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none ${isOpened ? 'open' : 'hidden'
          }`}
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all md:max-w-2xl md:w-full m-3 md:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* Header */}
            <div className="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3 className="font-bold text-gray-800 dark:text-white">
                Synch candidates for&nbsp;
                {isLoading && !interview ? (
                  <div
                    className="ml-2 animate-spin inline-block size-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                    role="status"
                    aria-label="loading"
                  />
                ) : (
                  interview?.title
                )}
              </h3>
              <button
                type="button"
                className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>

            {/* Body */}
            <div className="p-4 overflow-y-auto">
              <p className="mt-1 text-gray-800 text-sm dark:text-neutral-400">
                Select the TeamTailor job you'd like to sync Worklens candidates with.
              </p>
              <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                  <tr>
                    <td className="py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">
                      TeamTailor
                    </td>
                    <td className="py-4 text-end text-sm font-medium">
                      <div className="relative max-w-[300px] ml-auto text-gray-800 dark:text-neutral-200">
                        <select
                          value={selectedTeamTailorJobId === null ? '' : selectedTeamTailorJobId}
                          onChange={(e) =>
                            setSelectedTeamTailorJobId(e.target.value ? parseInt(e.target.value) : null)
                          }
                          className="py-2 pr-12 pl-4 flex items-center justify-end text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-blue-600 dark:text-white dark:hover:bg-blue-600 appearance-none w-full text-start"
                        >
                          <option value="">No job synced</option>
                          {teamTailorJobs.map((job) => (
                            <option key={job.id} value={job.id}>
                              {job.attributes.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Footer */}
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleSaveChanges}
                disabled={!hasChanges}
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`z-30 hs-overlay-backdrop transition duration fixed inset-0 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 dark:bg-neutral-900 ${isOpened ? 'open' : 'hidden'}`}></div>
    </>
  );
};

export default ModalSynchApplication;