import Settings from "./Settings";
import { InterviewStateType } from "./types";
import * as Sentry from "@sentry/react";

export const initiateLinkedInAuth = (redirectURL?: string) => {
  const state = {
    scope: 'openid profile email',
    redirectURL: redirectURL
  }
  
  const encodedState = encodeURIComponent(JSON.stringify(state));
  const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${Settings.CLIENT_ID_LINKEDIN_AUTH}&redirect_uri=${Settings.WEB_URL + "/login-callback-candidate"}&scope=openid%20profile%20email&state=${encodedState}`;

  window.location.href = authUrl;
};

export const initiateLinkedInThirdParty = () => {
  const state = JSON.stringify({ scope: 'r_dma_portability_3rd_party' });
  const encodedState = encodeURIComponent(state);
  localStorage.setItem("isImageAnalysis", "true");
  const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${Settings.CLIENT_ID_LINKEDIN_THIRDPARTY}&redirect_uri=${Settings.WEB_URL + "/login-callback-candidate"}&scope=r_dma_portability_3rd_party&state=${encodedState}`;

  window.location.href = authUrl;
};

export const upperCased = (text?: string) => {
  if (!text) return 'string not found';
  return text.replace('_', ' ').replace(/^\w/, (c) => c.toUpperCase());
}

export const getInterviewStateLabel = (state: number): string => {
  switch(state) {
    case InterviewStateType.Published:
      return "Published";
    case InterviewStateType.Draft:
      return "Draft";
    case InterviewStateType.Unpublished:
      return "Unpublished";
    case InterviewStateType.Expired:
      return "Expired";
    default:
      return "Unknown";
  }
}

export const logFatalSentryError = (descriptor?: string, error?: any) => {
  Sentry.withScope((scope) => {
    scope.setLevel("fatal");

    // Add descriptor to the scope if provided
    if (descriptor) {
      scope.setContext("Descriptor", { description: descriptor });
    }

    // Normalize and log the error
    if (error instanceof Error) {
      Sentry.captureException(error); // Standard Error object
    } else if (typeof error === "string") {
      Sentry.captureException(new Error(error)); // String converted to Error
    } else {
      // Non-standard error
      const normalizedError = new Error("Non-standard error logged");
      normalizedError.name = typeof error;
      normalizedError.message = JSON.stringify(error, null, 2);
      Sentry.captureException(normalizedError);
    }
  });
};

export const getInitials = (str1: string, str2: string): string => {
  if(str1.length && str2.length)
    return str1.slice(0, 1) + str2.slice(0, 1);
  else
    return str1
}

export const capitalizeFirstWord = (str: string | null | undefined): string => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString.replace(" ", "T"));

  return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false
  }).format(date);
}
